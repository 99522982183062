<template>
  <slot v-if="isAuthorized" name="default"></slot>
  <slot v-else name="unauthorized"></slot>
</template>

<script>
import { computed } from 'vue';
import { useStore } from 'vuex';

export default {
  name: 'AuthorizedArea',
  props: {
    roles: {
      type: Array, // String, Number, Boolean, Function, Object, Array
      required: true,
      default: null,
    },
  },
  setup(props) {
    const store = useStore();

    const hasAuthorization = store.getters['user/hasAuthorization'];

    const normalizedRoles = computed(() => {
      const roles = props.roles ?? [];

      roles.push('Developer');

      return roles;
    });

    const isAuthorized = computed(() => {
      return hasAuthorization(normalizedRoles.value);
    });

    return {
      isAuthorized,
    };
  },
};
</script>

<style lang="scss" scoped></style>
