<template>
  <v-text-field
    v-model.trim="internalValue"
    dense
    rounded
    clearable
    variant="underlined"
    placeholder="Search"
    prepend-inner-icon="mdi-magnify"
    class="shrink expanding-search"
    :style="searchFieldStyles"
    @focus="searchBoxClosed = false"
    @blur="searchBoxClosed = true"
  ></v-text-field>
</template>

<script>
import { ref, computed } from 'vue';
export default {
  name: 'ExpandingSearchField',
  props: {
    modelValue: {
      type: String, // String, Number, Boolean, Function, Object, Array
      default: null,
    },
  },
  emits: ['update:value'],
  setup(props, { emit }) {
    const searchBoxClosed = ref(true);

    const internalValue = computed({
      get() {
        return props.modelValue;
      },
      set(value) {
        emit('update:value', value);
      },
    });

    const searchFieldStyles = computed(() => {
      // 2023-01-29
      if (!searchBoxClosed.value || !!internalValue.value) return { 'max-width': '250px' };

      const styles = {
        'max-width': '30px',
      };

      return styles;
    });

    return {
      searchBoxClosed,
      internalValue,
      searchFieldStyles,
    };
  },
};
</script>

<style lang="scss" scoped>
.expanding-search {
  transition: all 0.5s;
}
</style>
