'use strict';

class Company {
  companyID = null;
  name = null;
  CVR = null;
  updated = null;
  created = null;

  static create(object) {
    if (object.constructor.name === 'Company') return object;

    const company = new Company();

    company.companyID = object.companyID;
    company.name = object.name;
    company.CVR = object.CVR;
    company.updated = object.updated;
    company.created = object.created;

    return company;
  }

  clone() {
    const data = JSON.parse(JSON.stringify(this));

    return Company.create(data);
  }

  getCompanyID() {
    return this.companyID;
  }

  getName() {
    return this.name;
  }

  getCVR() {
    return this.CVR;
  }
}

export default Company;
