import { createApp } from 'vue';
import App from './App.vue';
import './registerServiceWorker.mjs';
import router from '@router/index.mjs';
import store from '@store/index.mjs';

import Toast from '@plugins/vue-toastification.mjs';
import { i18n, loadLanguageAsync } from '@plugins/i18n.mjs';
// import axios from '@plugins/axios.mjs';
import vuetify from '@plugins/vuetify.mjs';

import '@mdi/font/css/materialdesignicons.min.css';

import '@assets/global.scss';

const app = createApp(App);

app.use(Toast);
app.use(i18n);
// app.use(axios);
app.use(vuetify);
app.use(store);
app.use(router);

await loadLanguageAsync('da-DK');

app.mount('#app');
