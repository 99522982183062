// 2023-02-17

import axios from 'axios';

class OfferAPI {
  static async fetchAll() {
    // 2023-02-17
    const url = '/api/offer/all';

    const response = await axios.get(url);

    return response;
  }

  static async fetchOffer(offerID) {
    // 2023-02-17
    const url = '/api/offer/' + encodeURIComponent(offerID);

    const response = await axios.get(url);

    return response;
  }

  static async fetchOfferByHash(offerHash) {
    return await OfferAPI.fetchOffer(offerHash);
  }

  static async pushOffer(iOffer) {
    // 2023-02-17

    const offerProductsData = [];
    for (const iOfferProducts of iOffer.getOfferProducts() ?? []) {
      offerProductsData.push({
        offerLineID: iOfferProducts.offerLineID,
        productID: iOfferProducts.productID,
        status: iOfferProducts.status,
        description: iOfferProducts.description,
        quantity: iOfferProducts.quantity,
        unit: iOfferProducts.unit,
        unitPrice: iOfferProducts.unitPrice,
        taxRate: iOfferProducts.taxRate,
        discountPercent: iOfferProducts.discountPercent,
        deleted: iOfferProducts.deleted,
        updated: iOfferProducts.updated,
        created: iOfferProducts.created,
      });
    }

    const data = {
      title: iOffer.title,
      dueDate: iOffer.dueDate,
      iOfferProducts: offerProductsData,
      deleted: iOffer.deleted,
      updated: iOffer.updated,
      created: iOffer.created,
    };

    const url = '/api/offer/' + encodeURIComponent(iOffer.getID() ?? '');

    const response = await axios.post(url, data);

    return response;
  }

  static async delete(offerID) {
    // 2023-02-17
    const url = '/api/offer/' + encodeURIComponent(offerID);

    const response = await axios.delete(url);

    return response;
  }
}

export default OfferAPI;
