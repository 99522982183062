<template>
  <flat-card :title="$t('Projects')">
    <template #head="{ title }">
      <div class="head d-flex align-center">
        <div class="text-h5">{{ title }}</div>
        <v-fade-transition>
          <authorized-area :roles="['Developer', 'Administrator']">
            <v-btn v-bind="attrs" icon="mdi-plus" variant="plain">
              <v-icon>mdi-plus</v-icon>
              <edit-project-dialog @save="saveProject"></edit-project-dialog>
            </v-btn>
          </authorized-area>
        </v-fade-transition>
      </div>
      <v-divider class="mb-4 mt-1"></v-divider>
    </template>
    <v-data-table
      style="width: 100%"
      :headers="headers"
      :items="items"
      item-key="projectID"
      :loading="itemsLoading"
      class="row-pointer"
      @click:row="rowClick"
    >
    </v-data-table>
  </flat-card>
</template>

<script>
import { ref, computed, onMounted } from 'vue';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';

import AuthorizedArea from '@components/AuthorizedArea.vue';
import FlatCard from '@components/FlatCard.vue';
import EditProjectDialog from '@components/EditProjectDialog.vue';

export default {
  name: 'CompanyProjectsOverview',
  components: { FlatCard, EditProjectDialog, AuthorizedArea },
  props: {
    companyID: {
      type: [String, Number], // String, Number, Boolean, Function, Object, Array
      required: true,
      default: null,
    },
  },
  emits: ['update:companyID'],
  setup(props) {
    const store = useStore();
    const { t } = useI18n();
    const router = useRouter();
    const toast = useToast();

    const headers = ref([
      {
        title: t('Name'),
        align: 'start',
        sortable: false,
        key: 'name',
      },
    ]);
    const projects = ref([]);
    const itemsLoading = ref(true);

    const getProjects = store.getters['company/getProjects'];

    const loadProjects = async () => {
      itemsLoading.value = true;

      try {
        await store.dispatch('company/fetchProjects', props.companyID);
      } catch (error) {
        console.error(error);

        toast.error(error?.response.data?.message || error.message);
      } finally {
        itemsLoading.value = false;
      }
    };

    const rowClick = (event, rowData) => {
      router.push({ name: 'Project', params: { projectID: rowData.item.projectID } });
    };

    const saveProject = (data) => {
      const { projectData, closeDialog } = data;

      store.dispatch('company/setProject', projectData);

      closeDialog();
    };

    const items = computed(() => {
      const projectItems = [];
      for (const project of getProjects(props.companyID)) {
        const item = {
          projectID: project.projectID,
          name: project.name,
        };

        projectItems.push(item);
      }

      return projectItems;
    });

    onMounted(() => {
      projects.value = getProjects(props.companyID);

      loadProjects();
    });

    return {
      headers,
      items,
      itemsLoading,
      rowClick,
      saveProject,
    };
  },
};
</script>

<style lang="scss" scoped></style>
