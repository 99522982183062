// 2023-09-24

class OfferProduct {
  static get UNITS() {
    const units = [
      'hours',
      'PCS.',
      'km',
      'days',
      'weeks',
      'months',
      'kg',
      'cubic meters',
      'set',
      'litres',
      'boxes',
      'cartons',
      'meters',
      'packages',
      'shipments',
      'sq.m',
      'sessions',
      'ton',
    ];

    return units;
  }

  static get STATUS_REQUIRED() {
    return 'REQUIRED';
  }
  static get STATUS_OPTIONAL() {
    return 'OPTIONAL';
  }

  offerLineID;
  offerID;
  productID;
  status = OfferProduct.STATUS_REQUIRED;
  description;
  _quantity;
  unit;
  unitPrice;
  _taxRate;
  _discountPercent;
  deleted;
  updated;
  created;

  constructor() {}

  static create(data) {
    const iOfferProduct = new OfferProduct();

    iOfferProduct.offerLineID = data.offerLineID;
    iOfferProduct.offerID = data.offerID;
    iOfferProduct.productID = data.productID;
    iOfferProduct.status = data.status ?? OfferProduct.STATUS_REQUIRED;
    iOfferProduct.description = data.description;
    iOfferProduct.quantity = data.quantity ?? data._quantity;
    iOfferProduct.unit = data.unit;
    iOfferProduct.unitPrice = data.unitPrice;
    iOfferProduct.taxRate = data.taxRate ?? data._taxRate;
    iOfferProduct.discountPercent = data.discountPercent ?? data._discountPercent;
    iOfferProduct.deleted = data.deleted;
    iOfferProduct.updated = data.updated;
    iOfferProduct.created = data.created;

    return iOfferProduct;
  }

  static createFromProduct(iProduct) {
    const data = {
      productID: iProduct.productID,
      name: iProduct.name,
      quantity: iProduct.quantity,
      unitPrice: iProduct.priceExTax,
      taxRate: iProduct.taxRate,
      unit: iProduct.unit,
      // description: iProduct.description,
      description: iProduct.name,
      deleted: null,
      updated: null,
      created: null,
    };

    return OfferProduct.create(data);
  }

  clone() {
    const data = JSON.parse(JSON.stringify(this));

    return OfferProduct.create(data);
  }

  getID() {
    return this.offerLineID;
  }

  getDescription() {
    return this.description;
  }

  getUnitPrice(addTax = false) {
    let price = this.unitPrice;

    if (addTax) price += this.calculateUnitTax();

    return price;
  }

  setUnitPrice(unitPrice, taxRate) {
    // Remove tax from unit price
    if (taxRate) unitPrice *= 1 / (1 + taxRate / 100);

    this.unitPrice = unitPrice;

    return this;
  }

  calculateTotalPrice(addTax = false) {
    let price = this.getUnitPrice(addTax) * this.quantity;

    price -= this.calculateTotalDiscount(addTax);

    return price;
  }

  static formatPrice(price) {
    const formatter = new Intl.NumberFormat('da-DK', {
      style: 'currency',
      currency: 'DKK',
    });

    return formatter.format(price);
  }

  static formatPercentage(percentage) {
    const formatter = new Intl.NumberFormat('da-DK', {
      style: 'percent',
      minimumFractionDigits: 2,
    });

    return formatter.format(percentage / 100);
  }

  get quantity() {
    return Number(this._quantity ?? 0);
  }

  set quantity(quantity) {
    this._quantity = Number(quantity ?? 0);
  }

  get taxRate() {
    return Number(this._taxRate ?? 0);
  }

  set taxRate(taxRate) {
    this._taxRate = Number(taxRate ?? 0);
  }

  get discountPercent() {
    return Number(this._discountPercent ?? 0);
  }

  set discountPercent(discountPercent) {
    this._discountPercent = Number(discountPercent ?? 0);
  }

  calculateTotalTax() {
    return this.unitPrice * (this.taxRate / 100) * this.quantity;
  }

  calculateUnitTax() {
    return this.unitPrice * (this.taxRate / 100);
  }

  getUnit() {
    return this.unit;
  }

  getTaxRate() {
    return this.taxRate;
  }

  getDiscountPercent() {
    return this.discountPercent ?? 0;
  }

  calculateTotalDiscount(addTax = false) {
    if (!this.discountPercent) return 0;

    return this.getUnitPrice(addTax) * (this.discountPercent / 100) * this.quantity;
  }

  getStatus() {
    return this.status;
  }

  setUnit(unit) {
    this.unit = unit;
  }

  setTaxRate(taxRate) {
    this.taxRate = taxRate;
  }

  setDiscountPercent(discountPercent) {
    this.discountPercent = discountPercent;
  }

  setStatus(status) {
    this.status = status;
  }

  setDescription(description) {
    this.description = description;
  }
}

export default OfferProduct;
