<template>
  <v-sheet class="d-flex flex-column justify-space-between" style="height: 100%" :color="color">
    <div class="head">
      <slot :title="title" name="head">
        <div class="text-h5">{{ title }}</div>
        <v-divider class="mb-4 mt-1"></v-divider>
      </slot>
    </div>
    <div class="body d-flex flex-column flex-grow-1">
      <slot></slot>
    </div>
    <div class="foot">
      <slot name="foot">
        <v-divider class="mt-4"></v-divider>
      </slot>
    </div>
  </v-sheet>
</template>

<script>
export default {
  name: 'FlatCard',

  props: {
    title: {
      type: String, // String, Number, Boolean, Function, Object, Array
      required: false,
      default: 'untitled',
    },
    color: {
      type: String, // String, Number, Boolean, Function, Object, Array
      required: false,
      default: 'transparent',
    },
  },
};
</script>
