import { createRouter, createWebHistory } from 'vue-router';

import NavigationShell from '@views/Navigation/NavigationShell.vue';
import Home from '@views/Home.vue';

const routes = [
  {
    path: '/admin',
    icon: '',
    authorizedRoles: ['Everyone'],
    component: NavigationShell,
    children: [
      {
        path: '/home',
        name: 'Home',
        icon: 'mdi-home',
        authorizedRoles: ['Everyone'],
        isSideNav: true,
        component: Home,
        children: [
          {
            path: '/home',
            authorizedRoles: ['Everyone'],
            isSideNav: false,
            component: () => import('@views/Company.vue'),
            props: true,
          },
        ],
      },
      {
        path: '/companies',
        name: 'Companies',
        icon: 'mdi-domain',
        authorizedRoles: ['Administrator'],
        group: 'Admin',
        isSideNav: true,
        component: () => import('@views/Companies.vue'),
      },
      {
        path: '/company/:companyID',
        name: 'Company',
        icon: '',
        authorizedRoles: ['Administrator', 'Employee', 'Customer'],
        isSideNav: false,
        component: () => import('@views/Company.vue'),
        props: true,
      },
      {
        path: '/users',
        name: 'Users',
        icon: 'mdi-account-group',
        authorizedRoles: ['Administrator'],
        group: 'Admin',
        isSideNav: true,
        component: () => import('@views/Users.vue'),
      },
      {
        path: '/user/:userID',
        name: 'User',
        icon: '',
        authorizedRoles: ['Administrator', 'Employee', 'Customer'],
        isSideNav: false,
        component: () => import('@views/User.vue'),
      },
      {
        path: '/projects',
        name: 'Projects',
        icon: '',
        authorizedRoles: ['Administrator'],
        group: 'Admin',
        isSideNav: false,
        component: () => import('@views/Projects.vue'),
      },
      {
        path: '/project/:projectID',
        name: 'Project',
        icon: '',
        authorizedRoles: ['Administrator', 'Employee', 'Customer'],
        isSideNav: false,
        component: () => import('@views/Project.vue'),
      },
      {
        path: '/tasks',
        name: 'Tasks',
        icon: '',
        authorizedRoles: ['Administrator'],
        group: 'Admin',
        isSideNav: false,
        component: () => import('@views/Tasks.vue'),
      },
      {
        path: '/task/:taskID',
        name: 'Task',
        icon: '',
        authorizedRoles: ['Administrator', 'Employee', 'Customer'],
        isSideNav: false,
        component: () => import('@views/Task.vue'),
      },
      {
        path: '/products',
        name: 'Products',
        group: 'Admin',
        // icon: 'mdi-package',
        icon: 'mdi-package-variant',
        authorizedRoles: ['Administrator', 'Employee'],
        isSideNav: true,
        component: () => import('@views/Products.vue'),
      },
      {
        path: '/offers',
        name: 'Offers',
        group: 'Admin',
        // icon: 'mdi-package',
        icon: 'mdi-offer',
        authorizedRoles: ['Administrator', 'Employee'],
        isSideNav: true,
        component: () => import('@views/Offers.vue'),
      },
      {
        path: '/offer/:offerID',
        name: 'Offer',
        group: 'Admin',
        // icon: 'mdi-package',
        icon: 'mdi-offer',
        authorizedRoles: ['Administrator', 'Employee'],
        isSideNav: false,
        component: () => import('@views/Offer.vue'),
        props: true,
      },
    ],
  },
  {
    path: '/',
    redirect: '/Home',
  },
  {
    path: '/login',
    name: 'Login',
    icon: 'login',
    authorizedRoles: ['Administrator'],
    component: () => import('@views/Login.vue'),
  },
  {
    path: '/offer-accept/:offerHashID',
    name: 'OfferAccept',
    component: () => import('@views/OfferAccept.vue'),
  },

  // {
  //   path: '/*',
  //   name: 'NotFound',
  //   icon: '',
  //   authorizedRoles: ['Everyone'],
  //   isSideNav: false,
  //   component: () => import('@views/NotFound.vue'),
  // },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
