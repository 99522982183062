<template>
  <select-dialog
    :loading="loadingUsers || loading"
    :items="items"
    :selected-items="selectedItems"
    item-value="userID"
    item-text="name"
    @select="select"
  >
  </select-dialog>
</template>

<script>
import { ref, computed, onMounted } from 'vue';
import { useToast } from 'vue-toastification';
import SelectDialog from '@components/SelectDialog.vue';
import UserAPI from '@api/UserAPI.mjs';

export default {
  name: 'UserSelectDialog',
  components: { SelectDialog },
  props: {
    usersData: {
      type: Array, // String, Number, Boolean, Function, Object, Array
      required: false,
      default: null,
    },
    selectedUserIds: {
      type: Array, // String, Number, Boolean, Function, Object, Array
      required: false,
      default: () => [],
    },
    loading: {
      type: Boolean, // String, Number, Boolean, Function, Object, Array
      required: false,
      default: false,
    },
  },
  emits: ['select'],
  setup(props, { emit }) {
    const toast = useToast();

    const loadingUsers = ref(true);
    const users = ref([]);

    const items = computed(() => {
      // 2023-01-18
      if (loadingUsers.value) return [];

      const userItems = [];
      for (const user of users.value) {
        user.name = user.givenName + ' ' + user.familyName;

        userItems.push(user);
      }

      return userItems;
    });

    const selectedItems = computed(() => {
      // 2023-01-18
      if (loadingUsers.value) return [];

      const sItems = [];
      for (const item of items.value) {
        if (props.selectedUserIds.includes(item.userID)) sItems.push(item);
      }

      return sItems;
    });

    const loadUsers = async () => {
      // 2023-01-19
      if (users.value.length > 0) return;

      loadingUsers.value = true;

      try {
        const response = await UserAPI.fetchUsers();

        users.value = response.data.users;
      } catch (error) {
        console.error(error);

        toast.error(error.message);
      } finally {
        loadingUsers.value = false;
      }
    };

    onMounted(() => {
      // 2023-01-18
      loadUsers();
    });

    const select = (data) => {
      // 2023-01-18
      emit('select', { users: data.items, closeDialog: data.closeDialog });
    };

    return {
      loadingUsers,
      users,
      items,
      selectedItems,
      loadUsers,
      select,
    };
  },
};
</script>

<style lang="scss" scoped></style>
