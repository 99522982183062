<template>
  <base-dialog activator="parent" :title="title" v-bind="$attrs">
    <!-- <template v-for="(_, slot) of $slots" #[slot]="scope"><slot :name="slot" v-bind="scope" /></template> -->
    <template #body>
      <v-form ref="form" :value="formValid">
        <v-row>
          <input v-model="iAddressEdit._addressID" type="hidden" />
          <v-col cols="12" sm="6">
            <v-select
              v-model="iAddressEdit._type"
              :items="types"
              :rules="typeRules"
              :label="$t('Type')"
              item-text="name"
              :disabled="formLoading"
              :loading="formLoading"
            ></v-select>
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              v-model="iAddressEdit._streetAddress"
              :label="$t('Street Address')"
              :rules="streetAddressRules"
              :counter="255"
              :disabled="formLoading"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              v-model="iAddressEdit._secondaryStreetAddress"
              :label="$t('Secondary Street Address')"
              :rules="secondaryStreetAddressRules"
              :counter="255"
              :disabled="formLoading"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              v-model="iAddressEdit._postalCode"
              :label="$t('Postal Code')"
              :rules="postalCodeRules"
              :counter="255"
              :disabled="formLoading"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              v-model="iAddressEdit._city"
              :label="$t('City')"
              :rules="cityRules"
              :counter="255"
              :disabled="formLoading"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              v-model="iAddressEdit._district"
              :label="$t('District')"
              :rules="districtRules"
              :counter="255"
              :disabled="formLoading"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6">
            <select-search
              v-model="iAddressEdit._countryID"
              :items="countries"
              :rules="countryIDRules"
              :label="$t('Country')"
              item-text="name"
              :disabled="formLoading"
              :loading="formLoading || !isCountriesLoaded"
            ></select-search>
          </v-col>
          <!-- <v-col cols="12" sm="6"> -->
          <!--     <v-select -->
          <!--         v-model="iAddressEdit._callingCode" -->
          <!--         :items="callingCodes" -->
          <!--         :rules="callingCodeRules" -->
          <!--         label="Calling Code" -->
          <!--         item-text="name" -->
          <!--         :disabled="formLoading" -->
          <!--         :loading="formLoading" -->
          <!--     ></v-select> -->
          <!-- </v-col> -->
          <v-col cols="12" sm="6">
            <vue-tel-input-vuetify
              label="Phone"
              :rules="phoneRules"
              :counter="255"
              :disabled="formLoading"
              default-country="dk"
              :disabled-fetching-country="true"
              mode="international"
              select-label="Code"
              :input-options="{ showDialCode: true }"
              @input="onInputPhone"
            ></vue-tel-input-vuetify>
          </v-col>
        </v-row>
      </v-form>
    </template>
    <template #actions="{ isActive }">
      <v-spacer></v-spacer>
      <v-btn plain :disabled="formLoading" title="Cancel" @click="isActive.value = false">{{ $t('Cancel') }}</v-btn>

      <v-btn color="primary" :disabled="formLoading" :loading="formLoading" title="Save" @click="save">{{
        $t('Save')
      }}</v-btn>
    </template>
  </base-dialog>
</template>

<script>
import { ref, computed, onMounted } from 'vue';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';

import Address from '@models/Address.mjs';

import BaseDialog from '@components/dialogs/BaseDialog.vue';
import SelectSearch from '@components/SelectSearch.vue';

export default {
  name: 'EditAddressDialog',
  components: { BaseDialog, SelectSearch },
  props: {
    // NOTE: maybe make iAddress to v-model instead
    iAddress: {
      type: Address, // String, Number, Boolean, Function, Object, Array
      required: false,
      default: null,
    },
  },
  emits: ['save', 'delete'],
  setup(props, { emit, refs, attrs }) {
    const store = useStore();
    const { t } = useI18n();

    const dialog = ref(false);
    const formLoading = ref(false);
    const formValid = ref(false);
    const iAddressEdit = ref(Address.create());
    const phone = ref({
      number: '',
      valid: false,
      country: undefined,
    });

    const loadCountries = async () => {
      await store.dispatch('country/fetchCountries');
    };

    const isCountriesLoaded = computed(() => store.getters['country/isCountriesLoaded']);

    onMounted(() => {
      if (!isCountriesLoaded.value) loadCountries();
    });

    const onInputPhone = (formattedNumber, data) => {
      const isValid = data.isValid;

      formValid.value = isValid;

      iAddressEdit.value._phone = isValid ? data.number.significant : null;

      iAddressEdit.value._callingCode = isValid ? '+' + data.country.dialCode : null;
    };

    const cancel = () => {
      dialog.value = false;
    };

    const validate = () => {
      return refs.form.validate();
    };

    const save = () => {
      if (!validate()) return;

      formLoading.value = true;

      const closeDialog = () => {
        dialog.value = false;
        formLoading.value = false;
      };

      if (!hasListener('save')) {
        closeDialog();
        return;
      }

      emit('save', iAddressEdit.value, closeDialog);
    };

    const deleteAddress = () => {
      formLoading.value = true;

      const closeDialog = () => {
        dialog.value = false;
        formLoading.value = false;
      };

      if (!hasListener('delete')) {
        closeDialog();
        return;
      }

      emit('delete', { addressData: iAddressEdit.value, closeDialog: closeDialog });
    };

    const hasListener = (iEventName) => {
      return (attrs?.[iEventName] ?? null) !== null;
    };

    const isCreatingAddress = computed(() => {
      return (props?.iAddress?.value ?? null) === null;
    });

    const addressID = computed(() => {
      if (isCreatingAddress.value) return null;

      return props.iAddress.value.getID();
    });

    const title = computed(() => {
      if (isCreatingAddress.value) return t('Create Address');

      return t('Edit Address #{addressID}', { addressID: props.iAddress.getID() });
    });

    const types = computed(() => {
      const addressTypes = [];

      for (const type of Address.ALL_TYPES) {
        addressTypes.push({ title: t('$ADDRESS_TYPE.' + type), value: type });
      }

      return addressTypes;
    });

    const countries = computed(() => {
      const countryItems = [];

      for (const iCountry of store.getters['country/getCountries']) {
        countryItems.push({ title: t(iCountry.getName()), value: iCountry.getID() });
      }

      return countryItems;
    });

    const typeRules = computed(() => {
      const rules = [(v) => !!v || t('Type is required')];

      return rules;
    });

    const streetAddressRules = computed(() => {
      const rules = [
        (v) => !!v || t('Street Address is required'),
        (v) => (v ?? '').length < 256 || t('Street Address must be less than 256 characters'),
      ];

      return rules;
    });

    const secondaryStreetAddressRules = computed(() => {
      const rules = [(v) => (v ?? '').length < 256 || t('Secondary Street Address must be less than 256 characters')];

      return rules;
    });

    const cityRules = computed(() => {
      const rules = [
        (v) => !!v || t('City is required'),
        (v) => (v ?? '').length < 256 || t('City must be less than 256 characters'),
      ];

      return rules;
    });

    const postalCodeRules = computed(() => {
      const rules = [
        (v) => !!v || t('Postal Code is required'),
        (v) => (v ?? '').length < 256 || t('Postal Code must be less than 256 characters'),
      ];

      return rules;
    });

    const countryIDRules = computed(() => {
      const rules = [(v) => !!v || t('Country is required')];

      return rules;
    });

    const phoneRules = computed(() => {
      const rules = [
        (v) => !!v || t('Phone is required'),
        (v) => (v ?? '').length < 256 || t('Phone must be less than 256 characters'),
      ];

      return rules;
    });

    const districtRules = computed(() => {
      const rules = [(v) => (v ?? '').length < 256 || t('District must be less than 256 characters')];

      return rules;
    });

    return {
      dialog,
      formLoading,
      isCountriesLoaded,
      formValid,
      iAddressEdit,
      phone,
      loadCountries,
      onInputPhone,
      cancel,
      validate,
      save,
      deleteAddress,
      hasListener,
      isCreatingAddress,
      addressID,
      title,
      types,
      countries,
      typeRules,
      streetAddressRules,
      secondaryStreetAddressRules,
      cityRules,
      postalCodeRules,
      countryIDRules,
      phoneRules,
      districtRules,
    };
  },
};
</script>

<style></style>
