<template>
  <div class="app">
    <router-view></router-view>
  </div>
</template>

<script>
import { computed, onMounted, watch } from 'vue';
import { useStore } from 'vuex';

export default {
  name: 'App',
  setup(props, { root }) {
    // Get Vuex store instance
    const store = useStore();

    // Map actions from "system" and "user" modules
    const initializeTheme = () => {
      store.dispatch('system/initializeTheme');
    };

    const isDarkTheme = computed(() => store.getters['system/isDarkTheme']);

    // Watch for theme changes
    watch(isDarkTheme, (value) => {
      root.$vuetify.theme.dark = value;
    });

    // On component mount
    onMounted(async () => {
      // Initialize theme
      initializeTheme();
    });

    return {
      initializeTheme,
    };
  },
};
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

/*
.app>.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}
*/
</style>
