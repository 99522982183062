import Utilities from '@models/Utilities.mjs';
import AddressAPI from '@api/AddressAPI.mjs';
import Address from '@models/Address.mjs';

const SET_ADDRESSES = 'SET_ADDRESSES';
const SET_ADDRESS = 'SET_ADDRESS';

export default {
  namespaced: true,
  state: {
    iAddresses: {},
  },
  getters: {
    getAddresses: (state) => {
      // 2023-01-27
      return Object.values(state.iAddresses);
    },
    getAddress: (state) => (addressID) => {
      // 2023-01-27
      return state.iAddresses[addressID] ?? null;
    },
  },
  mutations: {
    [SET_ADDRESSES]: (state, addresses) => {
      // 2023-01-27
      for (const address of addresses) {
        const iAddress = Address.create(address);

        state.iAddresses[address.addressID] = iAddress;
      }
    },
    [SET_ADDRESS]: (state, address) => {
      // 2023-01-27
      const iAddress = Address.create(address);

      state.iAddresses[address.addressID] = iAddress;
    },
  },
  actions: {
    fetchAddresses: async (context, addressIDs) => {
      // 2023-01-27
      const response = await AddressAPI.fetchAddresses(addressIDs ?? []);

      const addresses = response.data.addresses;

      context.commit(SET_ADDRESSES, addresses);
    },
    fetchAddress: async (context, addressID) => {
      // 2023-01-27
      const response = await AddressAPI.fetchAddress(addressID);

      context.commit(SET_ADDRESS, response.data.address);
    },
    pushAddress: async (context, companyData) => {
      // 2023-01-27
      const response = await AddressAPI.pushAddress(companyData);

      companyData.addressID = response.data.addressID;

      const address = context.getters.getAddress(companyData.addressID);

      const mergedAddress = Utilities.mergeDeep(address ?? {}, companyData);

      context.commit(SET_ADDRESS, mergedAddress);

      return response.data.message;
    },
  },
};
