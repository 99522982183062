// 2022-11-14

import axios from 'axios';

class ProjectAPI {
  static get TYPE_PASSWORD_SSH() {
    return 'SSH';
  }
  static get TYPE_PASSWORD_DATABASE() {
    return 'DATABASE';
  }
  static get TYPE_PASSWORD_WEB() {
    return 'WEB';
  }
  static get PASSWORD_TYPES() {
    const map = [
      ProjectAPI.TYPE_PASSWORD_SSH,
      ProjectAPI.TYPE_PASSWORD_DATABASE,
      ProjectAPI.TYPE_PASSWORD_WEB,
    ];

    return map;
  }

  static async fetchProjects() {
    // 2022-11-14
    const response = await axios.get('/api/project/fetch-all');

    return response;
  }

  static async fetchProject(projectID) {
    // 2022-11-14
    const response = await axios.get(
      '/api/project/' + encodeURIComponent(projectID),
    );

    return response;
  }

  static getDownloadURL(projectID, checksum) {
    // 2022-11-30
    return (
      '/api/project/' + encodeURIComponent(projectID) + '/file/' + checksum
    );
  }

  static async fetchTasks(projectID) {
    // 2022-11-16
    const response = await axios.get(
      '/api/project/' + encodeURIComponent(projectID) + '/fetch-all-tasks',
    );

    return response;
  }

  static async postProjectNote(projectID, note) {
    // 2022-12-23
    return await axios.post('/api/project/' + projectID + '/password', note);
  }

  static async pushProject(projectData) {
    // 2023-01-18
    const data = {
      name: projectData.name,
      companyID: projectData.companyID,
      standardPriority: projectData.standardPriority,
    };

    const response = await axios.post(
      '/api/project/' + (projectData.projectID ?? ''),
      { project: data },
    );

    return response;
  }

  static async deleteProject(projectID) {
    // 2023-01-22
    const url = '/api/project/' + encodeURIComponent(projectID);

    const response = await axios.delete(url);

    return response;
  }

  static async fetchPasswords(projectID) {
    // 2023-01-20
    const response = await axios.get(
      '/api/project/' + encodeURIComponent(projectID) + '/passwords',
    );

    return response;
  }

  static async fetchFiles(projectID) {
    // 2023-01-20
    const response = await axios.get(
      '/api/project/' + encodeURIComponent(projectID) + '/files',
    );

    return response;
  }

  static async pushPassword(projectPasswordData) {
    // 2023-01-21
    const data = {
      projectPasswordID: projectPasswordData?.projectPasswordID ?? null,
      projectID: projectPasswordData.projectID,
      title: projectPasswordData.title,
      type: projectPasswordData.type,
      isInternal: projectPasswordData.isInternal,
      host: projectPasswordData.host,
      username: projectPasswordData.username,
      password: projectPasswordData.password,
      description: projectPasswordData.description,
    };

    if (!ProjectAPI.PASSWORD_TYPES.includes(data.type))
      throw new Error('Unknown type "' + data.type + '"');

    const response = await axios.post(
      '/api/project/' + encodeURIComponent(data.projectID) + '/password',
      data,
    );

    return response;
  }

  static async deletePassword(projectID, projectPasswordID) {
    // 2023-01-21
    const response = await axios.delete(
      '/api/project/' +
        encodeURIComponent(projectID) +
        '/password/' +
        encodeURIComponent(projectPasswordID),
    );

    return response;
  }

  static async uploadFiles(
    projectID,
    filesFormData,
    progressCallback,
    controller,
  ) {
    // 2023-01-21
    const onUploadProgress = (progressEvent) => {
      const { loaded, total } = progressEvent;
      let percent = (loaded * 100) / total;

      progressCallback(percent, loaded, total);
    };

    const url = '/api/project/' + encodeURIComponent(projectID) + '/files';

    let options = {
      onUploadProgress: onUploadProgress,
    };

    if (controller) options['signal'] = controller.signal;

    const response = axios.post(url, filesFormData, options);

    return response;
  }

  static async deleteFile(projectID, fileID) {
    // 2023-01-22
    const url =
      '/api/project/' +
      encodeURIComponent(projectID) +
      '/file/' +
      encodeURIComponent(fileID);

    const response = await axios.delete(url);

    return response;
  }

  static async updatePriorities(projectID, priorityMap) {
    // 2023-02-04
    const url =
      '/api/project/' + encodeURIComponent(projectID) + '/set-tasks-priority';

    const response = await axios.post(url, { priorityMap });

    return response;
  }
}

export default ProjectAPI;
