<template>
  <flat-card :title="$t('Notes')">
    <template #head="{ title }">
      <div class="d-flex align-center">
        <div class="text-h5">{{ title }}</div>
        <authorized-area :roles="['Administrator']">
          <v-btn icon="mdi-plus" variant="plain" title="Add note">
            <v-icon>mdi-plus</v-icon>
            <edit-note-dialog @save="saveNote" @delete="deleteNote"></edit-note-dialog>
          </v-btn>
        </authorized-area>
        <v-spacer></v-spacer>
        <expanding-search-field v-model="searchText"></expanding-search-field>
      </div>
      <v-divider class="mb-4 mt-1"></v-divider>
    </template>
    <v-data-table
      :headers="headers"
      :items="notes"
      item-key="noteID"
      :loading="loading"
      :search="searchText"
      style="width: 100%"
    >
      <!-- eslint-disable-next-line vue/valid-v-slot -->
      <template #item.actions="{ item }">
        <v-btn icon="mdi-eye" variant="plain" small title="See description">
          <v-icon>mdi-eye</v-icon>
          <alert-dialog :title="$t('Description')" :text="item.content"></alert-dialog>
        </v-btn>
        <authorized-area :roles="['Administrator']">
          <v-btn icon="mdi-pencil" variant="plain" small :title="$t('Edit note')">
            <v-icon>mdi-pencil</v-icon>
            <edit-note-dialog :i-note="item.iNote" @save="saveNote" @delete="deleteNote"> </edit-note-dialog>
          </v-btn>
        </authorized-area>
      </template>
    </v-data-table>
  </flat-card>
</template>

<script>
import { ref, computed } from 'vue';
import { useI18n } from 'vue-i18n';

import FlatCard from '@components/FlatCard.vue';
import AuthorizedArea from '@components/AuthorizedArea.vue';
import AlertDialog from '@components/dialogs/AlertDialog.vue';
import ExpandingSearchField from '@components/ExpandingSearchField.vue';

import EditNoteDialog from '@views/Company/EditNoteDialog.vue';

export default {
  name: 'NotesCard',
  components: {
    FlatCard,
    AuthorizedArea,
    AlertDialog,
    EditNoteDialog,
    ExpandingSearchField,
  },
  props: {
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
    modelValue: {
      /** @type Note[] */
      type: Array,
      required: true,
      default: () => [],
    },
  },
  emits: ['delete-note', 'save-note', 'update:value'],
  setup(props, { emit }) {
    const { t } = useI18n();

    const searchText = ref('');
    const headers = ref([
      {
        title: t('Name'),
        align: 'start',
        sortable: true,
        filterable: true,
        key: 'name',
        class: 'text-start',
      },
      {
        title: t('Description'),
        align: 'start',
        sortable: false,
        filterable: true,
        key: 'content',
      },
      {
        title: t('Actions'),
        align: 'end',
        sortable: false,
        filterable: false,
        key: 'actions',
      },
    ]);

    const internalValue = computed({
      get() {
        return props.modelValue;
      },
      set(value) {
        emit('update:value', value);
      },
    });

    const notes = computed(() => {
      const tempNotes = [];

      for (const iNote of internalValue.value) {
        const note = {
          noteID: iNote.getID(),
          name: iNote.getName(),
          content: iNote.getContent() ?? '',
          actions: null,
          iNote: iNote,
        };

        tempNotes.push(note);
      }

      return tempNotes;
    });

    const saveNote = ({ iNote, closeDialog }) => {
      emit('save-note', { iNote, closeDialog });
    };

    const deleteNote = ({ iNote, closeDialog }) => {
      emit('delete-note', { iNote, closeDialog });
    };

    return {
      searchText,
      headers,
      notes,
      saveNote,
      deleteNote,
    };
  },
};
</script>

<style></style>
