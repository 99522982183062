// 2023-08-05

class Country {
  constructor() {
    this._countryID = null;
    this._name = null;
    this._alphaCode2 = null;
    this._alphaCode3 = null;
    this._numeric = null;
  }

  static create(country) {
    const iCountry = new Country();

    iCountry._countryID = country.countryID;
    iCountry._name = country.name;
    iCountry._alphaCode2 = country.alphaCode2;
    iCountry._alphaCode3 = country.alphaCode3;
    iCountry._numeric = country.numeric;

    return iCountry;
  }

  getID() {
    return this._countryID;
  }

  getName() {
    return this._name;
  }

  getAlphaCode2() {
    return this._alphaCode2;
  }

  getAlphaCode3() {
    return this._alphaCode3;
  }

  getNumeric() {
    return this._numeric;
  }
}

export default Country;
