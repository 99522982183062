// 2023-02-01

import axios from 'axios';

class ProductAPI {
  static async fetchAll() {
    // 2023-02-01
    const url = '/api/product/all';

    const response = await axios.get(url);

    return response;
  }

  static async pushProduct(iProduct) {
    // 2023-02-01
    const url = '/api/product/' + encodeURIComponent(iProduct?.productID ?? '');

    const response = await axios.post(url, iProduct);

    return response;
  }

  static async delete(productID) {
    const url = '/api/product/' + encodeURIComponent(productID);

    const response = await axios.delete(url);

    return response;
  }
}

export default ProductAPI;
