<!-- // 2022-11-16 -->
<template>
  <v-tooltip v-model="shown" location="top" :text="$t('Copied')">
    <template #activator="{ attrs }">
      <span class="saveToClipboard text-unselectable" v-bind="attrs" @click="copyTextToClipboard">
        <slot class="text-unselectable">{{ text }}</slot>
        <v-btn v-if="showIcon" variant="plain" :icon="statusIcon" @click="copyTextToClipboard"></v-btn>
      </span>
    </template>
  </v-tooltip>
</template>

<script>
import { ref, computed } from 'vue';
import { useToast } from 'vue-toastification';

export default {
  name: 'SaveToClipboard',
  props: {
    text: {
      type: String, // String, Number, Boolean, Function, Object, Array
      // required: true,
      default: null,
    },
    icon: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['clipboardSaved', 'clipboardFailed'],
  setup(props, { emit }) {
    const toast = useToast();

    const shown = ref(false);
    const timeoutID = ref(null);
    const status = ref('neutral');

    const statusIcon = computed(() => {
      if (status.value === 'neutral') {
        return 'mdi-clipboard-multiple';
      }

      if (status.value === 'success') {
        return 'mdi-clipboard-check';
      }

      if (status.value === 'failed') {
        return 'mdi-clipboard-remove';
      }

      return 'mdi-clipboard-multiple';
    });

    const copyTextToClipboard = async () => {
      clearTimeout(timeoutID.value);

      if (navigator.clipboard === undefined) {
        this.$toast.error('Your browser does not support clipboard API, or are on an insecure connection.');

        return;
      }

      try {
        await navigator.clipboard.writeText(props.text);

        status.value = 'success';

        emit('clipboardSaved', props.text);
      } catch (error) {
        console.error(error);

        status.value = 'failed';

        toast.error(error);

        emit('clipboardFailed', props.text);
      } finally {
        timeoutID.value = setTimeout(() => {
          shown.value = false;
          status.value = 'neutral';
        }, 500);
      }
    };

    const showIcon = computed(() => props.icon && !!props.text);

    return {
      shown,
      status,
      statusIcon,
      copyTextToClipboard,
      showIcon,
    };
  },
};
</script>

<style lang="scss" scoped>
.saveToClipboard {
  cursor: pointer;
}
</style>
