import { createStore } from 'vuex';

import user from '@store/modules/user.mjs';
import system from '@store/modules/system.mjs';
import company from '@store/modules/company.mjs';
import project from '@store/modules/project.mjs';
import task from '@store/modules/task.mjs';
import product from '@store/modules/product.mjs';
import offer from '@store/modules/offer.mjs';
import country from '@store/modules/country.mjs';
import address from '@store/modules/address.mjs';

export default createStore({
  modules: {
    user,
    system,
    company,
    project,
    task,
    product,
    offer,
    country,
    address,
  },
});
