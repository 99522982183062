import CountryAPI from '@api/CountryAPI.mjs';
import Country from '@models/Country.mjs';
import Utilities from '@models/Utilities.mjs';

const SET_COUNTRIES = 'SET_COUNTRIES';
const SET_COUNTRY = 'SET_COUNTRY';

export default {
  namespaced: true,
  state: {
    iCountries: {},
    countriesLoaded: false,
  },
  getters: {
    isCountriesLoaded: (state) => {
      // 2023-01-27
      return state.countriesLoaded;
    },
    getCountries: (state) => {
      // 2023-01-27
      return Object.values(state.iCountries);
    },
    getCountry: (state) => (countryID) => {
      // 2023-01-27
      return state.iCountries[countryID] ?? null;
    },
  },
  mutations: {
    [SET_COUNTRIES]: (state, countries) => {
      // 2023-01-27
      state.iCountries = {};

      for (const country of countries) {
        const iCountry = Country.create(country);

        state.iCountries[country.countryID] = iCountry;
      }

      state.countriesLoaded = true;
    },
    [SET_COUNTRY]: (state, country) => {
      // 2023-01-27
      const iCountry = Country.create(country);

      state.countries[country.countryID] = iCountry;
    },
  },
  actions: {
    fetchCountries: async (context, countryIDs) => {
      // 2023-01-27
      const response = await CountryAPI.fetchCountries(countryIDs ?? []);

      const countries = response.data;

      context.commit(SET_COUNTRIES, countries);
    },
    fetchCountry: async (context, countryID) => {
      // 2023-01-27
      const response = await CountryAPI.fetchCountry(countryID);

      context.commit(SET_COUNTRY, response.data.country);

      return response.data.message;
    },
    pushCountry: async (context, countryData) => {
      // 2023-01-27
      const response = await CountryAPI.pushCountry(countryData);

      countryData.countryID = response.data.countryID;

      const country = context.getters.getCountry(countryData.countryID);

      const mergedCountry = Utilities.mergeDeep(country ?? {}, countryData);

      context.commit(SET_COUNTRY, mergedCountry);

      return response.data.message;
    },
  },
};
