// 2023-08-05

'use strict';

class Note {
  constructor() {
    this._noteID = null;
    this._name = null;
    this._content = null;
  }

  static create(data) {
    data = data ?? {};

    if (data instanceof Note) return data;

    const iNote = new Note();

    iNote._noteID = data.noteID ?? null;
    iNote._name = data.name ?? null;
    iNote._content = data.content ?? null;

    return iNote;
  }

  clone() {
    const data = {
      noteID: this._noteID,
      name: this._name,
      content: this._content,
    };

    const iNote = Note.create(data);

    return iNote;
  }

  getID() {
    return this._noteID;
  }

  getName() {
    return this._name;
  }

  getContent() {
    return this._content;
  }
}

export default Note;
