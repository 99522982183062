<template>
  <v-select v-bind="$props" ref="select" :items="filteredItems" @blur="searchText = ''">
    <template v-for="(_, slot) of $slots" #[slot]="scope"><slot :name="slot" v-bind="scope" /></template>
    <template #prepend-item>
      <div class="px-2">
        <v-text-field v-model="searchText" label="Search"> </v-text-field>
      </div>
    </template>
  </v-select>
</template>

<script>
import { ref, computed, watch } from 'vue';
/*
	TODO: Animate entries entering and leaving when searching.
		  It should be possible by poviding a custom list item, and animating it with transitions
		  https://vuetifyjs.com/en/api/v-select/#slots
*/
export default {
  name: 'SelectSearch',
  props: {
    items: {
      type: Array, // String, Number, Boolean, Function, Object, Array
      required: false,
      default: null,
    },
  },
  setup(props) {
    const select = ref(null);
    const searchText = ref('');

    watch(select, () => {
      // 2023-02-01 setter
      searchText.value = '';
    });

    const filteredItems = computed(() => {
      // 2023-02-01
      return props.items.filter((item) => {
        const text = (item['title'] ?? '').trim();

        if (!text) return true;

        return text.trim().toLowerCase().search(searchText.value.toLowerCase()) !== -1;
      });
    });

    return {
      searchText,
      filteredItems,
    };
  },
};
</script>

<style lang="scss" scoped></style>
