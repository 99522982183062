// 2022-11-20

class Utilities {
  static emptyToNull(value) {
    if (!value) {
      if (typeof value === 'number') return value;

      return null;
    }

    return value;
  }

  static sleep(milliSeconds) {
    // 2022-12-17
    return new Promise((resolve) => {
      setTimeout(resolve, milliSeconds);
    });
  }

  /**
   * Simple object check.
   * @param item
   * @returns {boolean}
   */
  static isObject(item) {
    // 2023-01-27
    return item && typeof item === 'object' && !Array.isArray(item);
  }

  /**
   * Deep merge two objects.
   * @param target
   * @param ...sources
   */
  static mergeDeep(target, ...sources) {
    // 2023-01-27
    if (!sources.length) return target;

    const source = sources.shift();

    if (Utilities.isObject(target) && Utilities.isObject(source)) {
      for (const key in source) {
        if (Utilities.isObject(source[key])) {
          if (!target[key]) Object.assign(target, { [key]: {} });

          Utilities.mergeDeep(target[key], source[key]);
        } else {
          Object.assign(target, { [key]: source[key] });
        }
      }
    }

    return Utilities.mergeDeep(target, ...sources);
  }
}

export default Utilities;
