import ProductAPI from '@api/ProductAPI.mjs';
import Product from '@models/Product.mjs';

const SET_PRODUCTS = 'SET_PRODUCTS';
const SET_PRODUCT = 'SET_PRODUCT';
const SET_IS_FETCHING_PRODUCTS = 'SET_IS_FETCHING_PRODUCTS';

const REMOVE_PRODUCT = 'REMOVE_PRODUCT';

export default {
  namespaced: true,
  state: {
    iProducts: {},
    isFetchingProducts: false,
  },
  getters: {
    getProducts: (state) => {
      // 2023-01-27
      return Object.values(state.iProducts);
    },
    getProduct: (state) => (productID) => {
      // 2023-01-27
      return state.iProducts[productID] ?? null;
    },
    getIsFetchingProducts: (state) => {
      // 2023-02-25
      return state.isFetchingProducts;
    },
  },
  mutations: {
    [SET_PRODUCTS]: (state, products) => {
      // 2023-01-27
      state.iProducts = {};

      for (const product of products) {
        state.iProducts[product.productID] = Product.create(product);
      }
    },
    [SET_PRODUCT]: (state, product) => {
      // 2023-01-27
      state.iProducts[product.productID] = Product.create(product);
    },
    [SET_IS_FETCHING_PRODUCTS]: (state, bool) => {
      // 2023-02-25
      state.isFetchingProducts = bool == true;
    },
    [REMOVE_PRODUCT]: (state, productID) => {
      // 2023-01-27
      delete state.iProducts[productID];
    },
  },
  actions: {
    fetchProducts: async (context) => {
      // 2023-01-27
      context.commit(SET_IS_FETCHING_PRODUCTS, true);

      const response = await ProductAPI.fetchAll();

      const products = response.data.products;

      context.commit(SET_PRODUCTS, products);
      context.commit(SET_IS_FETCHING_PRODUCTS, false);
    },
    fetchProduct: async (context, productID) => {
      // 2023-01-27
      const response = await ProductAPI.fetchProduct(productID);

      context.commit(SET_PRODUCT, response.data.product);
    },
    pushProduct: async (context, iProduct) => {
      // 2023-02-01
      const response = await ProductAPI.pushProduct(iProduct);

      const product = response.data.product;

      context.commit(SET_PRODUCT, product);

      return response.data;
    },
    deleteProduct: async (context, productID) => {
      // 2023-02-01
      const response = await ProductAPI.delete(productID);

      context.commit(REMOVE_PRODUCT, productID);

      return response.data.message;
    },
  },
};
