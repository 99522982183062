<template>
  <base-dialog>
    <template #head>
      <div class="d-flex justify-space-between" style="width: 100%">
        <div class="text-h5">{{ editProjectTitle }}</div>
        <v-fade-transition>
          <v-btn icon="mdi-delete" variant="plain">
            <v-icon>mdi-delete</v-icon>
            <confirm-dialog :confirm-text="$t('Delete')" confirm-color="error" width="auto" @confirm="deleteProject">
              {{ $t('Are you sure you want to delete this project?') }}
            </confirm-dialog>
          </v-btn>
        </v-fade-transition>
      </div>
    </template>

    <template #body>
      <v-form ref="mainForm" v-model="formIsValid" lazy-validation :loading="formLoading">
        <v-row>
          <v-col cols="12" sm="6" md="6">
            <v-text-field
              v-model="formData.name"
              :label="$t('Name')"
              counter="255"
              :rules="nameRules"
              :disabled="formLoading"
              required
            ></v-text-field>
          </v-col>

          <v-col cols="12" sm="6" md="6">
            <v-select
              v-model="formData.companyID"
              :label="$t('Company')"
              :items="companies"
              :rules="companyIDRules"
              item-value="value"
              :disabled="formLoading"
              :loading="companiesLoading"
              required
            ></v-select>
          </v-col>

          <v-col cols="12" sm="6" md="6">
            <v-select
              v-model="formData.standardPriority"
              :label="$t('Standard Priority')"
              :items="priorities"
              :disabled="formLoading"
              required
            ></v-select>
          </v-col>
        </v-row>
      </v-form>
    </template>

    <template #actions="{ isActive }">
      <v-spacer></v-spacer>
      <v-btn plain :disabled="formLoading" @click="isActive.value = false">{{ $t('Cancel') }}</v-btn>
      <v-btn color="primary" :disabled="formLoading" :loading="formLoading" @click="save">{{ $t('Save') }}</v-btn>
    </template>
  </base-dialog>
</template>

<script>
import { ref, computed, onMounted } from 'vue';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';

import CompanyAPI from '@api/CompanyAPI.mjs';
import BaseDialog from '@components/dialogs/BaseDialog.vue';
import ConfirmDialog from '@components/dialogs/ConfirmDialog.vue';

export default {
  name: 'EditProjectDialog',
  components: { BaseDialog, ConfirmDialog },
  props: {
    projectData: {
      // 2023-01-18
      type: Object, // String, Number, Boolean, Function, Object, Array
      required: false,
      default: null,
    },
  },
  emits: ['save', 'delete'],
  setup(props, { emit, attrs, refs }) {
    const store = useStore();
    const { t } = useI18n();

    const dialog = ref(false);
    const deleteLoading = ref(false);

    const companiesLoading = ref(true);
    const companies = ref([]);

    const formIsValid = ref(true);
    const formLoading = ref(false);
    const formData = ref({
      name: '',
      companyID: null,
      standardPriority: 3,
    });

    const hasSaveListener = computed(() => {
      // 2023-01-18
      return (attrs?.onSave ?? null) !== null;
    });

    const hasDeleteListener = computed(() => {
      // 2023-01-18
      return (attrs?.onDelete ?? null) !== null;
    });

    const loadCompanies = async () => {
      // 2023-01-22
      try {
        const response = await CompanyAPI.fetchCompanies();

        const iCompanies = response.data.companies;

        if (iCompanies === null) return;

        companies.value = [];
        for (const iCompany of iCompanies) {
          const company = {
            value: iCompany.companyID,
            title: iCompany.name,
          };

          companies.value.push(company);
        }
      } catch (error) {
        console.error(error);

        store.dispatch('pushError', error.message ?? error);
      } finally {
        companiesLoading.value = false;
      }
    };

    const save = async () => {
      // 2023-01-18
      await refs.mainForm.validate();

      if (!formIsValid.value) return;

      formLoading.value = true;

      try {
        const data = {
          projectID: props.projectData?.projectID ?? null,
          name: formData.value.name,
          companyID: formData.value.companyID,
          standardPriority: formData.value.standardPriority,
        };

        const message = store.dispatch('pushProject', data);

        store.dispatch('pushSuccess', message);

        if (!hasSaveListener.value) dialog.value = false;

        const closeDialog = () => {
          dialog.value = false;
        };

        emit('save', { projectData: data, closeDialog: closeDialog });
      } catch (error) {
        console.error(error);

        store.dispatch('pushError', error.message ?? error);
      } finally {
        formLoading.value = hasSaveListener.value;
      }
    };

    const deleteProject = async () => {
      // 2023-01-19
      deleteLoading.value = true;

      try {
        const message = store.dispatch('pushProjectDeletion', props.projectData.projectID);

        store.dispatch('pushSuccess', message);

        const closeDialog = () => {
          dialog.value = false;
        };

        const data = {
          projectID: props.projectData.projectID,
          name: formData.value.name,
          companyID: formData.value.companyID,
        };

        if (!hasDeleteListener.value) closeDialog();

        emit('delete', { projectData: data, closeDialog: closeDialog });
      } catch (error) {
        console.error(error.message);

        store.dispatch('pushError', error.message);
      } finally {
        deleteLoading.value = false;
      }
    };

    const isCreatingProject = computed(() => {
      // 2023-01-19
      return (props.projectData?.projectID ?? null) === null;
    });

    const editProjectTitle = computed(() => {
      // 2023-01-18
      if (isCreatingProject.value) return t('Create Project');

      return t('Edit Project#{projectID}', { projectID: props.projectData.projectID });
    });

    const nameRules = computed(() => {
      // 2023-01-18
      const rules = [
        (v) => !!v || t('Name is required'),
        (v) => (v && v.length < 256) || t('Name must be less than 256 characters'),
      ];

      return rules;
    });

    const companyIDRules = computed(() => {
      // 2023-01-18
      const rules = [(v) => !!v || t('Company is required')];

      return rules;
    });

    const priorities = computed(() => {
      // 2023-02-04
      const priorityItems = [];
      for (let i = 1; i <= 100; i++) priorityItems.push(i);

      return priorityItems;
    });

    onMounted(() => {
      // 2023-01-18
      loadCompanies();
    });

    return {
      dialog,
      deleteLoading,
      companiesLoading,
      companies,
      formIsValid,
      formLoading,
      formData,
      hasSaveListener,
      hasDeleteListener,
      loadCompanies,
      save,
      deleteProject,
      isCreatingProject,
      editProjectTitle,
      nameRules,
      companyIDRules,
      priorities,
    };
  },
};
</script>

<style lang="scss" scoped></style>
