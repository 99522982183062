<template>
  <v-dialog v-bind="$props" max-width="720px" activator="parent">
    <template #default="{ isActive }">
      <slot name="default" :is-active="isActive">
        <v-card :loading="loading">
          <template #loader="loadingProps">
            <slot name="loader" :is-active="loadingProps.isActive">
              <v-progress-linear
                :active="loadingProps.isActive"
                color="primary"
                height="4"
                indeterminate
              ></v-progress-linear>
            </slot>
          </template>
          <v-card-title>
            <slot name="head" :is-active="isActive">{{ title }}</slot>
          </v-card-title>
          <v-card-text>
            <slot name="body" :is-active="isActive"></slot>
          </v-card-text>
          <v-card-actions>
            <slot name="actions" :is-active="isActive">
              <v-spacer></v-spacer>
              <v-btn plain @click="isActive.value = false">OK</v-btn>
            </slot>
          </v-card-actions>
        </v-card>
      </slot>
    </template>
  </v-dialog>
</template>

<script>
// import { VDialog } from 'vuetify';

export default {
  name: 'BaseDialog',
  // components: { VDialog },
  // extends: VDialog,
  props: {
    title: {
      type: String, // String, Number, Boolean, Function, Object, Array
      required: false,
      default: null,
    },
    loading: {
      type: Boolean, // String, Number, Boolean, Function, Object, Array
      required: false,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped></style>
