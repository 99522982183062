import TaskAPI from '@api/TaskAPI.mjs';
import Task from '@models/Task.mjs';

const SET_TASKS = 'SET_TASKS';
const SET_TASK = 'SET_TASK';
const REMOVE_TASK = 'REMOVE_TASK';

const SET_TASK_MESSAGES = 'SET_TASK_MESSAGES';
const SET_TASK_MESSAGE = 'SET_TASK_MESSAGE';
const REMOVE_TASK_MESSAGE = 'REMOVE_TASK_MESSAGE';
const SET_TASK_PRIORITIES = 'SET_TASK_PRIORITIES';

const SET_COMPANY = 'SET_COMPANY';

export default {
  namespaced: true,
  state: {
    iTasks: {},
    taskMessages: {},
    companies: {},
  },
  getters: {
    getTask: (state) => (taskID) => {
      // 2023-01-28
      return state.iTasks[taskID] ?? null;
    },
    getTasks: (state) => {
      // 2023-02-04
      return Object.values(state.iTasks);
    },
    getTaskMessages: (state) => (taskID) => {
      // 2023-01-28
      const taskMessages = state.taskMessages[taskID] ?? [];

      return taskMessages.filter(() => {
        return true;
      });
    },
    getCompany: (state) => (taskID) => {
      // 2023-01-28
      return state.companies[taskID] ?? null;
    },
  },
  mutations: {
    [SET_TASKS]: (state, tasks) => {
      // 2023-01-28
      for (const task of tasks) state.iTasks[task.taskID] = Task.create(task);
    },
    [SET_TASK]: (state, task) => {
      // 2023-01-28
      state.iTasks[task.taskID] = Task.create(task);
    },
    [REMOVE_TASK]: (state, taskID) => {
      // 2023-01-28
      delete state.iTasks[taskID];
      delete state.taskMessages[taskID];
      delete state.companies[taskID];
    },
    [SET_COMPANY]: (state, { taskID, company }) => {
      // 2023-01-28
      state.companies[taskID] = company;
    },
    [SET_TASK_MESSAGES]: (state, { taskID, taskMessages }) => {
      // 2023-01-28
      state.taskMessages[taskID] = taskMessages;
    },
    [SET_TASK_MESSAGE]: (state, taskMessage) => {
      // 2023-01-28
      const taskMessages = state.taskMessages[taskMessage.taskID] ?? [];

      const currentTaskMessage = taskMessages.reduce((curr, next) => {
        // 2023-01-28
        if (next.taskMessageID === taskMessage.taskMessageID) return next;

        return curr;
      }, null);

      const index = taskMessages.indexOf(currentTaskMessage);

      if (index === -1) {
        taskMessages.push(taskMessage);
        return;
      }

      state.taskMessages[taskMessage.taskID][index] = taskMessages;
    },
    [REMOVE_TASK_MESSAGE]: (state, { taskID, taskMessageID }) => {
      // 2023-01-28
      const filtered = (state.taskMessages[taskID] ?? []).filter((taskMessage) => {
        return taskMessage.taskMessageID !== taskMessageID;
      });

      state.taskMessages[taskID] = filtered;
    },
    [SET_TASK_PRIORITIES]: (state, taskPriorities) => {
      // 2023-02-04
      for (const [taskID, priority] of Object.entries(taskPriorities)) {
        const iTask = state.iTasks[taskID] ?? null;

        if (iTask === null) continue;

        iTask.setPriority(priority);
      }
    },
  },
  actions: {
    fetchTask: async (context, taskID) => {
      // 2023-01-28
      const response = await TaskAPI.fetchTask(taskID);

      const task = response.data.task;

      context.commit(SET_TASK, task);

      return task;
    },
    pushTask: async (context, taskData) => {
      // 2023-01-28
      const assignedUserIDs = [];

      for (const assignedUser of taskData.assignedUsers) assignedUserIDs.push(assignedUser.userID);

      const data = {
        taskID: taskData?.taskID ?? null,
        name: taskData?.name,
        priority: taskData?.priority,
        assignedUserIDs: assignedUserIDs,
        remark: taskData?.remark,
        type: taskData?.type,
        projectID: taskData?.projectID,
        QAUserID: taskData?.QAUserID,
      };

      const response = await TaskAPI.pushTask(data);

      const taskID = response.data.taskID;

      taskData.taskID = taskID;

      context.commit(SET_TASK, taskData);

      const task = await context.dispatch('fetchTask', taskID);

      return { message: response.data.message, task };
    },
    pushTaskStatusChange: async (context, { taskID, usersToNotify, status }) => {
      // 2023-01-28
      const statusChangeAPICallMap = {
        [TaskAPI.STATUS_OPEN]: TaskAPI.STATUS_OPEN,
        [TaskAPI.STATUS_CLOSE]: TaskAPI.STATUS_CLOSE,
      };

      const changeStatusFunc = statusChangeAPICallMap[status];

      const response = await changeStatusFunc(taskID, usersToNotify ?? []);

      const message = response.data.message;
      const statusTaskMessage = response.data.statusMessage;
      const closedAt = response.data.closedAt;

      const task = context.getters.getTask(taskID);

      if (task) {
        task.closedAt = closedAt;

        context.commit(SET_TASK, task);
      }

      context.commit(SET_TASK_MESSAGE, statusTaskMessage);

      return message;
    },
    pushTaskDeletion: async (context, taskID) => {
      // 2023-01-28
      const response = await TaskAPI.deleteTask(taskID);

      const message = response.data.message;

      context.commit(REMOVE_TASK, taskID);

      return message;
    },
    fetchCompany: async (context, taskID) => {
      // 2023-01-28
      const response = await TaskAPI.fetchCompany(taskID);

      const company = response.data.company;

      context.commit(SET_COMPANY, { taskID, company });

      return response.data.message;
    },
    fetchTaskMessage: async () => {
      // 2023-01-28
    },
    fetchTaskMessages: async (context, taskID) => {
      // 2023-01-28
      const response = await TaskAPI.fetchTaskMessages(taskID);

      const taskMessages = response.data.messages;

      context.commit(SET_TASK_MESSAGES, { taskID, taskMessages });

      return response.data.message;
    },
    pushTaskMessage: async (context, taskMessageData) => {
      // 2023-01-28
      const formData = new FormData();
      for (const [index, file] of taskMessageData.files.entries()) formData.append('file ' + index, file);

      formData.append('html', taskMessageData.text);
      formData.append('isInternalComment', taskMessageData.isInternalComment);
      formData.append('userIDsToNotify', taskMessageData.usersToNotify);

      const response = await TaskAPI.pushTaskMessage(taskMessageData.taskID, formData);

      const taskMessage = response.data.taskMessage;
      const message = response.data.message;

      context.commit(SET_TASK_MESSAGE, taskMessage);

      return message;
    },
    pushTaskMessageDeletion: async (context, { taskID, taskMessageID }) => {
      // 2023-01-28
      const response = await TaskAPI.deleteTaskMessage(taskID, taskMessageID);

      context.commit(REMOVE_TASK_MESSAGE, { taskID, taskMessageID });

      const message = response.data.message;

      return message;
    },
  },
};
