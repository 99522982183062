<template>
  <base-dialog v-model="dialog" v-bind="$attrs">
    <template #body>
      <v-form ref="mainForm">
        <v-checkbox
          v-for="item in dataItems"
          :key="item"
          v-model="dataSelectedItems"
          :label="item[itemText]"
          :value="item"
          hide-details
        ></v-checkbox>
      </v-form>
    </template>
    <template #actions="{ isActive }">
      <v-spacer></v-spacer>
      <v-btn plain @click="isActive.value = false">{{ $t('Cancel') }}</v-btn>
      <v-btn color="primary" :disabled="loading" :loading="loading" @click="select">{{ $t('Select') }}</v-btn>
    </template>
  </base-dialog>
</template>

<script>
import { ref, computed, watch } from 'vue';
import BaseDialog from '@components/dialogs/BaseDialog.vue';
export default {
  name: 'SelectDialog',
  components: { BaseDialog },
  extends: BaseDialog,
  props: {
    title: {
      type: String, // String, Number, Boolean, Function, Object, Array
      required: false,
      default: 'Select',
    },
    itemValue: {
      type: String, // String, Number, Boolean, Function, Object, Array
      required: false,
      default: 'value',
    },
    itemText: {
      type: String, // String, Number, Boolean, Function, Object, Array
      required: false,
      default: 'text',
    },
    items: {
      type: Array, // String, Number, Boolean, Function, Object, Array
      required: true,
      default: () => [],
    },
    selectedItems: {
      type: Array, // String, Number, Boolean, Function, Object, Array
      required: false,
      default: () => [],
    },
    loading: {
      type: Boolean, // String, Number, Boolean, Function, Object, Array
      required: false,
      default: false,
    },
  },
  emits: ['select'],
  setup(props, { emit }) {
    const dialog = ref(false);
    const itemsLoaded = ref(false);
    const dataSelectedItems = ref([]);

    const hasTitle = computed(() => {
      // 2023-01-18
      const title = props.title ?? '';

      return title.trim() !== '';
    });

    watch(
      dialog,
      () => {
        // 2023-01-18
        dataSelectedItems.value = [];
        for (const item of props.selectedItems) {
          dataSelectedItems.value.push(item);
        }
      },
      { immediate: true, deep: true },
    );

    const dataItems = computed(() => {
      // 2023-01-18
      const items = [];

      for (const item of props.items) {
        items.push(item);
      }

      return items;
    });

    const select = () => {
      // 2023-01-18
      const closeDialog = () => (dialog.value = false);

      emit('select', { items: dataSelectedItems.value, closeDialog: closeDialog });
    };

    const cancel = () => {
      // 2023-01-18
      dialog.value = false;
    };

    return {
      dialog,
      itemsLoaded,
      dataItems,
      dataSelectedItems,
      hasTitle,
      select,
      cancel,
    };
  },
};
</script>

<style lang="scss" scoped></style>
