const SET_DARK_MODE_ENABLED = 'SET_DARK_MODE_ENABLED';
const ADD_SNACKBAR = 'ADD_SNACKBAR';
const SET_SNACKBARS = 'SET_SNACKBARS';
const REMOVE_SNACKBAR = 'REMOVE_SNACKBAR';
const TOGGLE_DRAWER = 'TOGGLE_DRAWER';
const SET_DRAWER = 'SET_DRAWER';

export default {
  namespaced: true,
  state: {
    snackbars: [],
    drawer: false,

    darkmode: false,
  },
  getters: {
    getSnackBars(state) {
      // 2022-11-12
      return state.snackbars;
    },
    getDrawerIsOpen(state) {
      // 2022-11-12
      return state.drawer;
    },
    isInDarkMode: (state) => {
      // 2023-01-29
      return state.darkmode;
    },
  },
  mutations: {
    [ADD_SNACKBAR]: (state, snackbar) => {
      // 2022-11-12
      state.snackbars.push(snackbar);
    },
    [SET_SNACKBARS]: (state, snackbars) => {
      // 2022-11-12
      state.snackbars = snackbars;
    },
    [REMOVE_SNACKBAR]: () => {
      // 2022-11-12
    },
    [TOGGLE_DRAWER]: (state) => {
      // 2022-11-12
      state.drawer = !state.drawer;
    },
    [SET_DRAWER]: (state, value) => {
      // 2022-11-12
      state.drawer = value;
    },
    [SET_DARK_MODE_ENABLED]: (state, enabled) => {
      // 2023-01-29
      state.darkmode = enabled;
      localStorage.setItem('DarkMode', JSON.stringify(state.darkmode));
    },
  },
  actions: {
    addSnackbar(context, snackbar) {
      // 2022-11-12
      snackbar.uuid = Math.random();
      snackbar.color = snackbar.color ?? '';
      snackbar.text = snackbar.text ?? '';

      const showing = snackbar.showing ?? true;

      snackbar.showing = false;

      context.commit('ADD_SNACKBAR', snackbar);

      setTimeout(() => {
        snackbar.showing = showing;
      }, 10);
    },
    removeSnackbar() {
      // 2022-11-12
    },
    toggleDrawer(context) {
      // 2022-11-12
      context.commit(TOGGLE_DRAWER);
    },
    setDrawer(context, value) {
      // 2022-11-12
      context.commit(SET_DRAWER, value);
    },
    toggleDarkMode: (context, enable) => {
      // 2023-01-29
      context.commit(SET_DARK_MODE_ENABLED, enable === true);
    },
    initializeTheme: (context) => {
      // 2023-01-29
      const darkModeEnabled = localStorage.getItem('DarkMode') === 'true';

      context.commit(SET_DARK_MODE_ENABLED, darkModeEnabled);
    },
  },
};
