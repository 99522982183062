<template>
  <v-app-bar prominent>
    <template #prepend>
      <v-app-bar-nav-icon @click.stop="toggleDrawer">
        <v-img :width="40" aspect-ratio="1/1" cover :src="logo"></v-img>
      </v-app-bar-nav-icon>
      <v-app-bar-title class="ml-1 subtitle-1">
        <router-link style="text-decoration: none; color: inherit" :to="{ name: 'Home' }"> Cedulas </router-link>
      </v-app-bar-title>
    </template>
    <template #append>
      <v-menu min-width="200px" rounded :close-on-content-click="false">
        <template #activator="{ props }">
          <v-btn icon v-bind="props">
            <v-avatar color="brown" size="large">
              <span class="text-h5">{{ userInitials }}</span>
            </v-avatar>
          </v-btn>
        </template>
        <v-card>
          <v-card-text>
            <div class="mx-auto text-center">
              <v-avatar color="brown">
                <span class="text-h5">{{ userInitials }}</span>
              </v-avatar>
              <h3>{{ userFullName }}</h3>
              <p class="text-caption mt-1">
                {{ user.emailAddress }}
              </p>
              <v-divider class="my-3"></v-divider>
              <v-switch
                v-model="darkmode"
                color="primary"
                hide-details
                inset
                :label="$t('Darkmode')"
                @change="toggleDarkMode"
              ></v-switch>
              <v-divider v-if="isAuthenticated" class="my-3"></v-divider>
              <v-btn
                v-if="isAuthenticated"
                rounded
                variant="text"
                :to="{ name: 'User', params: { userID: user.userID } }"
                >{{ $t('Edit Account') }}</v-btn
              >
              <v-divider class="my-3"></v-divider>
              <v-btn rounded variant="text">{{ $t('Disconnect') }}</v-btn>
            </div>
          </v-card-text>
        </v-card>
      </v-menu>
    </template>
    <v-spacer></v-spacer>
  </v-app-bar>
</template>

<script>
import { useToast } from 'vue-toastification';
import { ref, computed, onMounted, watch } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { useTheme } from 'vuetify';

import logo from '@assets/logo.png';

export default {
  name: 'NavigationTopbar',
  components: {},
  setup() {
    const store = useStore();
    const toast = useToast();
    const router = useRouter();
    const theme = useTheme();

    const darkmode = ref(false);

    const drawerIsOpen = computed(() => store.getters['system/getDrawerIsOpen']);
    const isInDarkMode = computed(() => store.getters['system/isInDarkMode']);
    const user = computed(() => store.getters['user/getUser']);
    const isAuthenticated = computed(() => store.getters['user/isAuthenticated']);

    const userInitials = computed(() => {
      if (!isAuthenticated.value) return 'N/A';

      return user.value?.givenName?.charAt(0) + user.value?.familyName?.charAt(0);
    });

    const userFullName = computed(() => {
      if (!isAuthenticated.value) return 'N/A';

      return user.value.givenName + ' ' + user.value.familyName;
    });

    const toggleDrawer = () => {
      store.dispatch('system/toggleDrawer');
    };

    const toggleDarkMode = () => {
      store.dispatch('system/toggleDarkMode', darkmode.value);
    };

    const setTheme = (isDarkmode) => {
      store.dispatch('system/toggleDarkMode', isDarkmode);

      theme.global.current.value.dark = isDarkmode;
      darkmode.value = isDarkmode;

      theme.global.name.value = 'aggressivePrimaryDarkTheme';
      // theme.global.name.value = theme.global.current.value.dark ? 'cedulasDarkTheme' : 'cedulasLightTheme';
    };

    watch(isInDarkMode, setTheme);
    watch(darkmode, setTheme);

    const setDrawer = (value) => {
      store.dispatch('system/setDrawer', value);
    };

    const logout = () => {
      store.dispatch('user/logout');
    };

    const logoutUser = async () => {
      // 2023-01-22
      try {
        await logout();

        router.go('Login');
      } catch (error) {
        console.error(error);

        toast.error(error.message);
      }
    };

    const hasAuthorization = store.getters['user/hasAuthorization'];

    const groups = ref([]);

    onMounted(() => {
      darkmode.value = isInDarkMode.value;

      store.dispatch('system/initializeTheme');
    });

    return {
      drawerIsOpen,
      isInDarkMode,
      user,
      isAuthenticated,
      userInitials,
      userFullName,
      toggleDrawer,
      toggleDarkMode,
      setDrawer,
      logout,
      logoutUser,
      hasAuthorization,
      groups,
      darkmode,
      logo,
    };
  },
};
</script>

<style lang="scss"></style>
