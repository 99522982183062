import OfferAPI from '@api/OfferAPI.mjs';
import Offer from '@models/Offer.mjs';

const SET_OFFERS = 'SET_OFFERS';
const SET_OFFER = 'SET_OFFER';
const REMOVE_OFFER = 'REMOVE_OFFER';
const FETCHING_OFFERS = 'FETCHING_OFFERS';

export default {
  namespaced: true,
  state: {
    iOffers: {},
    fetchingOffers: false,
  },
  getters: {
    getOffers: (state) => {
      // 2023-01-27
      return Object.values(state.iOffers);
    },
    getOffer: (state) => (offerID) => {
      // 2023-01-27
      return state.iOffers[offerID] ?? null;
    },
    getUsers: () => {
      // 2023-02-01
    },
    isFetchingOffers: (state) => {
      // 2023-03-01
      return state.fetchingOffers;
    },
  },
  mutations: {
    [SET_OFFERS]: (state, offers) => {
      // 2023-01-27
      state.iOffers = {};

      for (const offer of offers) {
        const iOffer = Offer.create(offer);
        state.iOffers[iOffer.getID()] = iOffer;
      }
    },
    [SET_OFFER]: (state, offer) => {
      // 2023-01-27
      const iOffer = Offer.create(offer);
      state.iOffers[iOffer.getID()] = iOffer;
    },
    [REMOVE_OFFER]: (state, offerID) => {
      // 2023-01-27
      delete state.iOffers[offerID];
    },
    [FETCHING_OFFERS]: (state, doingIt) => {
      // 2023-03-01
      state.fetchingOffers = doingIt == true;
    },
  },
  actions: {
    fetchOffers: async (context) => {
      // 2023-01-27
      context.commit(FETCHING_OFFERS, true);

      await OfferAPI.fetchAll();

      // const offers = response.data.offers;
      // TODO: Implement new API
      const offers = [
        {
          dueDate: '2023-07-25T22:00:00.000Z',
          offerID: 2,
          offerProducts: [
            {
              status: 'REQUIRED',
              description: 'Tester',
              discountPercent: 10,
              offerLineID: 2,
              productID: 6,
              quantity: 1,
              taxRate: 25,
              unit: 'months',
              unitPrice: 500,
            },
          ],
          title: 'Tester',
        },
      ];

      context.commit(SET_OFFERS, offers);
      context.commit(FETCHING_OFFERS, false);
    },
    fetchOffer: async (context, offerID) => {
      // 2023-01-27
      const response = await OfferAPI.fetchOffer(offerID);

      context.commit(SET_OFFER, response.data.offer);
    },

    fetchOfferByHash: async (context, offerHashID) => {
      const response = await OfferAPI.fetchOfferByHash(offerHashID);

      context.commit(SET_OFFER, response.data.offer);

      return response;
    },
    pushOffer: async (context, offerData) => {
      // 2023-02-01
      const response = await OfferAPI.pushOffer(offerData);

      const offer = response.data.offerData;
      const message = response.data.message;

      context.commit(SET_OFFER, offer);

      return { message: message, offer: offer };
    },
    deleteOffer: async (context, offerID) => {
      // 2023-02-01
      const response = await OfferAPI.delete(offerID);

      const offer = response.data.offerData;
      const message = response.data.message;

      context.commit(REMOVE_OFFER, offerID);

      return { message: message, offer: offer };
    },
  },
};
