'use strict';

import store from '@store/index.mjs';

class Task {
  // table.increments('taskID');
  // table.integer('projectID').unsigned().notNullable();
  // table.integer('QAUserID').unsigned().defaultsTo(null);
  // table.integer('createdByUserID').unsigned().notNullable();
  // table.enum('type', ['INTERNAL', 'EXTERNAL']).notNullable().defaultsTo('EXTERNAL');
  //
  // table.integer('priority').unsigned().notNullable().defaultsTo(3);
  // table.string('name').notNullable();
  // table.string('remark').defaultsTo(null); // The best would be to have this in a differenct table, so we can see the history
  //
  // table.datetime('closedAt', { useTz: false }).defaultsTo(null);
  // // table.integer('completedByUserID').unsigned().defaultsTo(null);
  //
  // table.datetime('updated', { useTz: false }).notNullable();
  // table.datetime('created', { useTz: false }).notNullable();

  taskID = null;
  projectID = null;
  QAUserID = null;
  createdByUserID = null;
  createdByUser = null;
  type = null;
  priority = null;
  name = null;
  remark = null;
  closedAt = null;
  updated = null;
  created = null;

  static get STATUS_OPEN() {
    return 'OPEN';
  }
  static get STATUS_CLOSE() {
    return 'CLOSE';
  }

  static create(data) {
    const iTask = new Task();

    iTask.taskID = data.taskID;
    iTask.projectID = data.projectID;
    iTask.QAUserID = data.QAUserID;
    iTask.createdByUserID = data.createdByUserID;
    iTask._temp_create_by_user = data?.createdByUser ?? null;
    iTask._temp_assigned_users = data?.assignedUsers ?? [];
    iTask.type = data.type;
    iTask.priority = data.priority;
    iTask.name = data.name;
    iTask.remark = data.remark;
    iTask.closedAt = data.closedAt;
    iTask.updated = data.updated;
    iTask.created = data.created;

    return iTask;
  }

  clone() {
    const data = JSON.parse(JSON.stringify(this));
    console.log('data', data);

    return Task.create(data);
  }

  getID() {
    return this.taskID;
  }

  _temp_assigned_users = [];
  getAssignedUsers() {
    // TODO: get assigned users from the store
    return this._temp_assigned_users;
  }

  // eslint-disable-next-line no-unused-vars
  setAssignedUsers(assignedUsers) {
    // TODO: set assigned user in the store
    this._temp_assigned_users = assignedUsers;
  }

  getProjectID() {
    return this.projectID;
  }

  getQAUserID() {
    return this.QAUserID;
  }

  getCreatedByUser() {
    return this._temp_create_by_user;

    const user = store.getters['user/getUserByID'](this.createdByUserID);

    if (!user) {
      store.dispatch('user/fetchUserByID', this.createdByUserID);

      return store.getters['user/getUserByID'](this.createdByUserID);
    }
  }

  getCreatedByUserID() {
    return this.createdByUserID;
  }

  getType() {
    return this.type;
  }

  getPriority() {
    return this.priority;
  }

  getName() {
    return this.name;
  }

  getRemark() {
    return this.remark;
  }

  getClosedAt() {
    return this.closedAt;
  }

  getUpdated() {
    return this.updated;
  }

  getCreated() {
    return this.created;
  }
}

export default Task;
