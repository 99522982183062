<template>
  <v-navigation-drawer v-model="drawerIsOpen" location="left" temporary>
    <v-list :items="items"></v-list>
  </v-navigation-drawer>
</template>

<script>
import { ref, computed, onBeforeMount } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';

export default {
  name: 'NavigationSidebar',
  components: {},
  setup() {
    const store = useStore();
    const router = useRouter();
    const { t } = useI18n();

    const drawerIsOpen = computed({
      get: () => store.getters['system/getDrawerIsOpen'],
      set: (value) => store.dispatch('system/setDrawer', value),
    });

    const setDrawer = (value) => {
      store.dispatch('system/setDrawer', value);
    };

    const hasAuthorization = store.getters['user/hasAuthorization'];

    const items = ref([]);

    onBeforeMount(() => {
      const groupedRoutes = {};

      for (const route of router.options.routes[0].children) {
        if (!route.isSideNav || !hasAuthorization(route.authorizedRoles ?? [])) continue;

        const group = route.group ?? 'NONE';

        groupedRoutes[group] = groupedRoutes[group] ?? [];

        const item = {
          title: t(route.name),
          value: route.name,
          props: {
            prependIcon: route.icon,
            link: true,
            to: route.path,
            icon: route.icon,
          },
        };

        groupedRoutes[group].push(item);
      }

      const orderMap = [
        {
          key: 'NONE',
          name: null,
          hasDivider: false,
        },
        {
          key: 'Admin',
          name: 'Admin',
          hasDivider: true,
        },
      ];

      for (const order of orderMap) {
        const routes = groupedRoutes[order.key] ?? [];

        if (routes.length === 0) continue;

        if (order.hasDivider) items.value.push({ type: 'divider' });

        items.value.push({ type: 'subheader', title: order.name });

        items.value = items.value.concat(routes);
      }
    });

    return {
      drawerIsOpen,
      setDrawer,
      hasAuthorization,
      items,
    };
  },
};
</script>

<style></style>
