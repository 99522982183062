import axios from 'axios';

const CURRENT_USER_FETCHED = 'CURRENT_USER_FETCHED';
const USER_LOGGED_OUT = 'USER_LOGGED_OUT';

const SET_USERS = 'SET_USERS';
const SET_USER = 'SET_USER';

export default {
  namespaced: true,
  state: {
    users: {},
    user: {
      userID: null,
      emailAddress: null,
      givenName: null,
      familyName: null,
      roles: {},
    },
  },
  getters: {
    isAuthenticated: (state) => {
      // 2023-01-22
      return !!state.user.userID;
    },
    hasAuthorization: (state) => (roles) => {
      // 2023-01-27
      if (roles.includes('Everyone')) return true;

      roles.push('Developer'); // Allowed everywhere

      const userRoles = Object.values(state.user.roles);

      const intersectingRoles = userRoles.filter((role) => roles.includes(role));

      return intersectingRoles.length !== 0;
    },
    // Should be renamed to getCurrentUser
    getUser: (state) => {
      // 2023-01-22
      return state.user;
    },
    getUsers: (state) => {
      // 2023-02-01
      return Object.values(state.users);
    },
    getUserByID: (state) => (userID) => {
      // 2023-10-29
      return state.users[userID];
    },
    usersFetched: (state) => {
      // 2023-02-01
      return state.users.length !== 0;
    },
  },
  mutations: {
    [CURRENT_USER_FETCHED]: (state, user) => {
      // 2023-01-22
      state.user.userID = user.userID;
      state.user.emailAddress = user.emailAddress;
      state.user.givenName = user.givenName;
      state.user.familyName = user.familyName;
      state.user.roles = user.roles;
    },
    [USER_LOGGED_OUT]: (state) => {
      // 2023-01-22
      state.userID = null;
      state.emailAddress = null;
      state.givenName = null;
      state.familyName = null;
      state.roles = {};
    },
    [SET_USERS]: (state, users) => {
      // 2023-02-01
      state.users = users;
    },
    [SET_USER]: (state, user) => {
      // 2023-10-29
      state.users[user.userID] = user;
    },
  },
  actions: {
    initialLoad: async (context) => {
      // 2023-01-22
      const res = await axios.get('/api/authentication/current-user');

      if (res.status === 200) {
        context.commit('CURRENT_USER_FETCHED', res.data.user);
      }
    },
    logout: async (context) => {
      // 2023-01-22
      const response = await axios.get('/api/authentication/logout');

      if (response.status !== 200) throw new Error(response.data.message);

      context.commit('USER_LOGGED_OUT');
    },
    clearUser: (context) => {
      // 2023-01-25
      context.commit('USER_LOGGED_OUT');
    },
    fetchUsers: async (context) => {
      // 2023-02-01
      const response = await axios.get('/api/user/fetch-all');

      const message = response.data.message;
      const users = response.data.users;

      context.commit('SET_USERS', users);

      return { message, users };
    },
    fetchUserByID: async (context, userID) => {
      // 2023-10-29
      const response = await axios.get(`/api/user/${userID}`);

      const message = response.data.message;
      const user = response.data.user;

      context.commit('SET_USER', user);

      return { message, user };
    },
  },
};
