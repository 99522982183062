<template>
  <flat-card :title="title">
    <template #head="{ title }">
      <div class="d-flex align-center">
        <div class="text-h5">{{ title }}</div>
      </div>
      <v-divider class="mb-4 mt-1"></v-divider>
    </template>
    <v-table>
      <tbody>
        <tr>
          <th align="left">Street Addreess</th>
          <td>
            <save-to-clipboard :text="streetAddress"></save-to-clipboard>
          </td>
        </tr>
        <tr>
          <th align="left">Secondary Street Addreess</th>
          <td>
            <save-to-clipboard :text="secondaryStreetAddress"></save-to-clipboard>
          </td>
        </tr>
        <tr>
          <th align="left">Postal Code</th>
          <td>
            <save-to-clipboard :text="postalCode"></save-to-clipboard>
          </td>
        </tr>
        <tr>
          <th align="left">City</th>
          <td>
            <save-to-clipboard :text="city"></save-to-clipboard>
          </td>
        </tr>
        <tr>
          <th align="left">District</th>
          <td>
            <save-to-clipboard :text="district"></save-to-clipboard>
          </td>
        </tr>
        <tr>
          <th align="left">Country</th>
          <td>
            <save-to-clipboard :text="country"></save-to-clipboard>
          </td>
        </tr>
        <tr>
          <th align="left">Phone</th>
          <td>
            <save-to-clipboard :text="phone"></save-to-clipboard>
          </td>
        </tr>
      </tbody>
    </v-table>
  </flat-card>
</template>

<script>
// { "data":
// 	[
// 		{
// 			"addressID": 1,
// 			"type": "BILLING",
// 			"streetAddress": "Bavnevej 162 Mellerup",
// 			"secondaryStreetAddress": null,
// 			"district": "Randers",
// 			"city": "Randers",
// 			"postalCode": "8900",
// 			"callingCode": "+45",
// 			"phone": "26 20 46 71",
// 			"countryID": 60,
// 			"deleted": null,
// 			"updated": "2022-12-18 01:31:21",
// 			"created": "2022-12-18 01:31:21"
// 		}
// 	]
// }

import { computed } from 'vue';

import Address from '@models/Address.mjs';

import FlatCard from '@components/FlatCard.vue';
import SaveToClipboard from '@components/SaveToClipboard.vue';

export default {
  name: 'AddressCard',
  components: {
    FlatCard,
    SaveToClipboard,
  },
  props: {
    address: {
      type: Address,
      required: true,
      default: null,
    },
  },
  setup(props) {
    const titleTypeMap = {
      BILLING: 'Billing Address',
      SHIPPING: 'Shipping Address',
      DELIVERY: 'Delivery Address',
      OTHER: 'Other Address',
    };

    const title = computed(() => {
      return titleTypeMap[props.address.getType()];
    });

    const addressID = computed(() => {
      return props.address.getID();
    });

    const type = computed(() => {
      return props.address.getType();
    });

    const streetAddress = computed(() => {
      return props.address.getStreetAddress();
    });

    const secondaryStreetAddress = computed(() => {
      return props.address.getSecondaryStreetAddress();
    });

    const postalCode = computed(() => {
      return props.address.getPostalCode();
    });

    const city = computed(() => {
      return props.address.getCity();
    });

    const district = computed(() => {
      return props.address.getDistrict();
    });

    const country = computed(() => {
      return props.address.getCountry().getName();
    });

    const phone = computed(() => {
      return props.address.getPhone();
    });

    return {
      titleTypeMap,
      title,
      addressID,
      type,
      streetAddress,
      secondaryStreetAddress,
      postalCode,
      city,
      district,
      country,
      phone,
    };
  },
};
</script>
