// 2023-08-05

import { useStore } from 'vuex';

class Address {
  static get TYPE_BILLING() {
    return 'BILLING';
  }
  static get TYPE_SHIPPING() {
    return 'SHIPPING';
  }
  static get TYPE_DELIVERY() {
    return 'DELIVERY';
  }
  static get TYPE_OTHER() {
    return 'OTHER';
  }

  static get ALL_TYPES() {
    return [
      Address.TYPE_BILLING,
      Address.TYPE_SHIPPING,
      Address.TYPE_DELIVERY,
      Address.TYPE_OTHER,
    ];
  }

  constructor() {
    this._addressID = null;
    this._type = Address.TYPE_OTHER;

    this._streetAddress = null;
    this._secondaryStreetAddress = null;
    this._district = null;
    this._city = null;
    this._postalCode = null;
    this._phone = null;
    this._countryID = null;
  }

  static create(data) {
    data = data ?? {};

    if (data instanceof Address) return data;

    const address = new Address();

    address._addressID = data.addressID ?? null;
    address._type = data.type ?? null;
    address._streetAddress = data.streetAddress ?? null;
    address._secondaryStreetAddress = data.secondaryStreetAddress ?? null;
    address._district = data.district ?? null;
    address._city = data.city ?? null;
    address._postalCode = data.postalCode ?? null;
    address._callingCode = data.callingCode ?? null;
    address._phone = data.phone ?? null;
    address._countryID = data.countryID ?? null;

    return address;
  }

  clone() {
    const data = {
      addressID: this._addressID,
      type: this._type,
      streetAddress: this._streetAddress,
      secondaryStreetAddress: this._secondaryStreetAddress,
      district: this._district,
      city: this._city,
      postalCode: this._postalCode,
      callingCode: this._callingCode,
      phone: this._phone,
      countryID: this._countryID,
    };

    return Address.create(data);
  }

  getID() {
    return this._addressID;
  }

  getType() {
    return this._type;
  }

  getStreetAddress() {
    return this._streetAddress;
  }

  getSecondaryStreetAddress() {
    return this._secondaryStreetAddress;
  }

  getPostalCode() {
    return this._postalCode;
  }

  getCity() {
    return this._city;
  }

  getDistrict() {
    return this._district;
  }

  getCountryID() {
    return this._countryID;
  }

  getCountry() {
    const store = useStore();
    return store.getters['country/getCountry'](this._countryID);
  }

  getCallingCode() {
    return this._callingCode;
  }

  getPhone() {
    return this._phone;
  }
}

export default Address;
