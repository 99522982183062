// Vuetify
import 'vuetify/styles';
import { createVuetify } from 'vuetify';
import * as components from 'vuetify/components';
import * as directives from 'vuetify/directives';

import { VDataTable, VDataTableServer, VDataTableVirtual } from 'vuetify/labs/VDataTable';
import { VDatePicker } from 'vuetify/labs/VDatePicker';

const cedulasLightTheme = {
  dark: false,
  colors: {
    // background: '#FFFFFF',
    // surface: '#FFFFFF',
    primary: '#4A981F',
    'primary-darken-1': '#1C2F2B',
    secondary: '#232f1d',
    'secondary-darken-1': '#162622',
    // secondary: '#232f1d',
    // 'secondary-darken-1': '#182114',
    // error: '#B00020',
    // info: '#2196F3',
    // success: '#4CAF50',
    // warning: '#FB8C00',
  },
};

const cedulasDarkTheme = {
  dark: true,
  colors: {
    // background: '#FFFFFF',
    // surface: '#FFFFFF',
    primary: '#4A981F',
    'primary-darken-1': '#4A981F',
    secondary: '#232f1d',
    'secondary-darken-1': '#162622',
    // secondary: '#232f1d',
    // 'secondary-darken-1': '#182114',
    // error: '#B00020',
    // info: '#2196F3',
    // success: '#4CAF50',
    // warning: '#FB8C00',
  },
};

const theme1 = {
  dark: false,
  colors: {
    background: '#ffffff',
    surface: '#f2f2f2',
    primary: '#90df66ff',
    'primary-darken-1': '#80cb5aff',
    secondary: '#1de2a7ff',
    'secondary-darken-1': '#1cc698ff',
    error: '#ff5252',
    info: '#2196F3',
    success: '#4CAF50',
    warning: '#FB8C00',
  },
};

const theme2 = {
  dark: false,
  colors: {
    background: '#ffffff',
    surface: '#f2f2f2',
    primary: '#1de2a7ff',
    'primary-darken-1': '#1cc698ff',
    secondary: '#90df66ff',
    'secondary-darken-1': '#80cb5aff',
    error: '#ff5252',
    info: '#2196F3',
    success: '#4CAF50',
    warning: '#FB8C00',
  },
};

const theme3 = {
  dark: false,
  colors: {
    background: '#ffffff',
    surface: '#f2f2f2',
    primary: '#90df66ff',
    'primary-darken-1': '#80cb5aff',
    secondary: '#1cc698ff',
    'secondary-darken-1': '#1ab287ff',
    error: '#ff5252',
    info: '#2196F3',
    success: '#4CAF50',
    warning: '#FB8C00',
  },
};

const aggressivePrimaryDarkTheme = {
  dark: true,
  colors: {
    background: '#121212',
    surface: '#1e1e1e',
    primary: '#90df66ff', // Original, vibrant color for primary
    'primary-darken-1': '#80cb5aff',
    secondary: '#16927a', // Darkened and desaturated version of #1de2a7ff
    'secondary-darken-1': '#136a65',
    error: '#CF6679',
    info: '#64B5F6',
    success: '#81C784',
    warning: '#FFB74D',
  },
};

const vuetify = createVuetify({
  components: {
    VDataTable,
    VDataTableServer,
    VDataTableVirtual,
    VDatePicker,
    ...components,
  },
  directives,
  icons: {
    iconfont: 'mdi', // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4' || 'faSvg'
  },
  theme: {
    defaultTheme: 'cedulasLightTheme',
    themes: {
      cedulasLightTheme,
      cedulasDarkTheme,
      theme1,
      theme2,
      theme3,
      aggressivePrimaryDarkTheme,
    },
  },
});

export default vuetify;
