import Utilities from '@models/Utilities.mjs';
import Project from '@api/ProjectAPI.mjs';

const SET_PROJECTS = 'SET_PROJECTS';

const SET_PROJECT = 'SET_PROJECT';
const REMOVE_PROJECT = 'REMOVE_PROJECT';

const SET_PASSWORDS = 'SET_PASSWORDS';
const SET_PASSWORD = 'SET_PASSWORD';
const REMOVE_PASSOWRD = 'REMOVE_PASSOWRD';

const SET_FILES = 'SET_FILES';
const SET_FILE = 'SET_FILE';
const REMOVE_FILE = 'REMOVE_FILE';

export default {
  namespaced: true,
  state: {
    projects: {},
    passwords: {},
    files: {},
  },
  getters: {
    getProjects: (state) => {
      // 2023-01-27
      return Object.values(state.projects);
    },
    getProject: (state) => (projectID) => {
      // 2023-01-27
      return state.projects[projectID] ?? null;
    },
    getTasks: (state, getters, rootState, rootGetters) => (projectID) => {
      // 2023-01-27
      const iTasks = rootGetters['task/getTasks'];

      const projectTasks = iTasks.filter((iTask) => iTask.getProjectID() == projectID);

      return projectTasks;
    },
    getTask: (state, getters) => (projectID, taskID) => {
      // 2023-01-28
      const tasks = getters.getTasks(projectID) ?? [];

      const task = tasks.reduce((curr, next) => {
        // 2023-01-28
        if (next.taskID === taskID) return next;

        return curr;
      }, null);

      return task;
    },
    getPasswords: (state) => (projectID) => {
      // 2023-01-27
      return state.passwords[projectID] ?? [];
    },
    getFiles: (state) => (projectID) => {
      // 2023-01-27
      return state.files[projectID] ?? [];
    },
  },
  mutations: {
    [SET_PROJECTS]: (state, projects) => {
      // 2023-01-27
      state.projects = projects;
    },
    [SET_PROJECT]: (state, project) => {
      // 2023-01-27
      state.projects[project.projectID] = project;
    },
    [SET_PASSWORDS]: (state, { projectID, passwords }) => {
      // 2023-01-27
      state.passwords[projectID] = passwords;
    },
    [SET_PASSWORD]: (state, { projectID, password }) => {
      // 2023-01-28
      state.passwords[projectID].push(password);

      // Vue.set(state.passwords[projectID], password.passwordID, password);
    },
    [REMOVE_PASSOWRD]: (state, { projectID, password }) => {
      // 2023-01-28
      delete state.passwords[projectID][password.passwordID];
    },
    [SET_FILES]: (state, { projectID, files }) => {
      // 2023-01-27
      state.files[projectID] = files;
    },
    [SET_FILE]: (state, { projectID, file }) => {
      // 2023-01-27
      state.files[projectID].push(file);
    },
    [REMOVE_FILE]: (state, { projectID, fileID }) => {
      // 2023-01-29
      const file = state.files[projectID].reduce((curr, next) => {
        // 2023-01-29
        if (next.fileID === fileID) return next;

        return curr;
      }, null);

      const index = state.files[projectID].indexOf(file);

      if (index === -1) return;

      state.files[projectID].splice(index, 1);
    },
    [REMOVE_PROJECT]: (state, projectID) => {
      // 2023-01-28
      delete state.projects[projectID];
    },
  },
  actions: {
    fetchProject: async (context, projectID) => {
      // 2023-01-27
      const response = await Project.fetchProject(projectID);

      const project = response.data.project;

      context.commit(SET_PROJECT, project);
    },
    pushProject: async (context, projectData) => {
      // 2023-01-28
      const response = await Project.pushProject(projectData);

      projectData.projectID = response.data.projectID;

      const project = context.getters.getProject(projectData.projectID);

      const mergedProject = Utilities.mergeDeep(project ?? {}, projectData);

      context.commit(SET_PROJECT, mergedProject);

      return response.data.message;
    },
    pushProjectDeletion: async (context, projectID) => {
      // 2023-01-28
      const response = await Project.deleteProject(projectID);

      context.commit(REMOVE_PROJECT, projectID);

      return response.data.message;
    },
    fetchTasks: async (context, projectID) => {
      // 2023-01-27
      const response = await Project.fetchTasks(projectID);

      const tasks = response.data.tasks;

      context.commit('task/SET_TASKS', tasks, { root: true });
      // context.commit(SET_TASKS, { projectID, tasks });
    },
    fetchPasswords: async (context, projectID) => {
      // 2023-01-27
      const response = await Project.fetchPasswords(projectID);

      const passwords = response.data.passwords;

      context.commit(SET_PASSWORDS, { projectID, passwords });

      return response.data.message;
    },
    pushPassword: async (context, passwordData) => {
      // 2023-01-28
      const response = await Project.pushPassword(passwordData);

      const password = passwordData.password;
      const projectID = passwordData.projectID;

      context.commit(SET_PASSWORD, { projectID, password });

      return response.data.message;
    },
    pushPasswordDeletion: async (context, { projectID, passwordID }) => {
      // 2023-01-28
      const response = await Project.deletePassword(projectID, passwordID);

      context.commit(REMOVE_PASSOWRD, { projectID, passwordID });

      const message = response.data.message;

      return message;
    },
    fetchFiles: async (context, projectID) => {
      // 2023-01-29
      const response = await Project.fetchFiles(projectID);

      const files = response.data.files;

      context.commit(SET_FILES, { projectID, files });
    },
    pushFiles: async (context, { projectID, files, progressCallback, uploadAbortController }) => {
      // 2023-01-29
      const formData = new FormData();
      for (const [index, file] of files.entries()) formData.append('file ' + index, file);

      const response = await Project.uploadFiles(projectID, formData, progressCallback, uploadAbortController);

      const message = response.data.message;
      const createdFiles = response.data.files;

      for (const file of createdFiles) {
        context.commit(SET_FILE, { projectID, file });
      }

      return message;
    },
    pushFileDeletion: async (context, { projectID, fileID }) => {
      // 2023-01-29
      const response = await Project.deleteFile(projectID, fileID);

      const message = response.data.message;

      context.commit(REMOVE_FILE, { projectID, fileID });

      return message;
    },
    pushUpdateTaskPriorities: async (context, { projectID, tasks }) => {
      // 2023-02-04
      const taskPriorityMap = {};
      for (const task of tasks) taskPriorityMap[task.taskID] = task.priority;

      const response = await Project.updatePriorities(projectID, taskPriorityMap);

      const message = response.data.message;

      context.commit('task/SET_TASK_PRIORITIES', taskPriorityMap, {
        root: true,
      });

      return message;
    },
  },
};
