// 2022-11-12

'use strict';

import axios from 'axios';

class CompanyAPI {
  // 2022-11-12
  companyID = null;

  constructor(companyID) {
    // 2023-01-18
    this.companyID = companyID;
  }

  static async fetchCompany(companyID /*: ?object*/) {
    // 2022-11-12
    const response = await axios.get('/api/company/' + companyID);

    return response;
  }

  static async fetchCompanies() {
    // 2022-12-17
    const response = await axios.get('/api/company/fetch-all');

    return response;
  }

  static async fetchUsers(companyID /*: ?array*/) {
    // 2022-11-13
    const response = await axios.get(
      '/api/company/' + encodeURIComponent(companyID) + '/users',
    );

    return response;
  }

  static async pushAssignedUsers(companyID, userIDs /*: ?array*/) {
    // 2022-11-13
    const data = {
      userIDs: userIDs,
    };

    const response = await axios.post(
      '/api/company/' + encodeURIComponent(companyID) + '/users',
      data,
    );

    return response;
  }

  static async fetchProjects(companyID) {
    // 2022-11-14
    const response = await axios.get(
      '/api/company/' + encodeURIComponent(companyID) + '/project/fetch-all',
    );

    return response;
  }

  static async pushCompany(companyData) {
    // 2023-01-18
    const data = {
      name: companyData.name,
      cvr: companyData.cvr,
      addresses: companyData.addresses,
    };

    const response = await axios.post(
      '/api/company/' + (companyData.companyID ?? ''),
      data,
    );

    return response;
  }

  static async fetchAddresses(companyID) {
    // 2023-08-05
    const response = await axios.get(
      '/api/company/' + encodeURIComponent(companyID) + '/address/fetch-all',
    );

    return response;
  }

  static async fetchNotes(companyID) {
    // 2023-08-05
    const response = await axios.get(
      '/api/company/' + encodeURIComponent(companyID) + '/note/fetch-all',
    );

    return response;
  }

  static async pushNote(companyID, iNote) {
    // 2023-08-05
    const response = await axios.post(
      '/api/company/' + encodeURIComponent(companyID) + '/note',
      { noteData: iNote },
    );

    return response;
  }

  static async deleteNoteByID(companyID, noteID) {
    // 2023-08-05
    const response = await axios.delete(
      '/api/company/' +
        encodeURIComponent(companyID) +
        '/note/' +
        encodeURIComponent(noteID),
    );

    return response;
  }
}

export default CompanyAPI;
