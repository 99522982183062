<template>
  <base-dialog v-bind="$props">
    <template #body>
      <slot>
        <pre class="text-body-1">{{ text }}</pre>
      </slot>
    </template>
  </base-dialog>
</template>

<script>
import BaseDialog from '@components/dialogs/BaseDialog.vue';

export default {
  name: 'AlertDialog',
  components: { BaseDialog },
  extends: BaseDialog,
  props: {
    text: {
      type: String, // String, Number, Boolean, Function, Object, Array
      required: true,
      default: null,
    },
  },
};
</script>

<style lang="scss" scoped></style>
