<template>
  <section class="Company pa-4">
    <div class="head d-flex mb-6">
      <div class="header">
        <h4 class="text-h4 font-weight-medium">{{ companyName }}</h4>
        <h5 class="text-subtitle-2 text-medium-emphasis font-weight-light pl-2 text-left">{{ companyCVR }}</h5>
      </div>
      <authorized-area :roles="['Developer', 'Administrator']">
        <v-btn :disabled="companyLoading" icon="mdi-pencil" variant="plain" class="ml-2" v-bind="props">
          <v-icon>mdi-pencil</v-icon>
          <edit-company-dialog :i-company="iCompany" @save="companySave"></edit-company-dialog>
        </v-btn>
      </authorized-area>

      <v-spacer></v-spacer>

      <v-tabs v-model="tab">
        <v-tab value="company">{{ $t('Company') }}</v-tab>
        <authorized-area :roles="['Employee']">
          <v-tab value="details">{{ $t('Details') }}</v-tab>
        </authorized-area>
      </v-tabs>
    </div>

    <v-window v-model="tab">
      <v-window-item value="company">
        <company-overview-tab :company-i-d="normalizedCompanyID"></company-overview-tab>
      </v-window-item>
      <authorized-area :roles="['Employee']">
        <v-window-item value="details">
          <company-details-tab :company-i-d="normalizedCompanyID"></company-details-tab>
        </v-window-item>
      </authorized-area>
    </v-window>
  </section>
</template>

<script>
import { ref, computed, onMounted } from 'vue';
import { useStore } from 'vuex';
import { useToast } from 'vue-toastification';

import AuthorizedArea from '@components/AuthorizedArea.vue';
import EditCompanyDialog from '@components/EditCompanyDialog.vue';

import CompanyOverviewTab from '@views/Company/CompanyOverviewTab.vue';
import CompanyDetailsTab from '@views/Company/CompanyDetailsTab.vue';

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Company',
  components: {
    EditCompanyDialog,
    AuthorizedArea,
    CompanyOverviewTab,
    CompanyDetailsTab,
  },
  props: {
    companyID: {
      type: [Number, String],
      required: true,
      default: null,
    },
  },
  setup(props) {
    const loading = ref(false);
    const iCompany = ref(null);
    const users = ref([]);
    const tab = ref(null);

    const store = useStore();
    const toast = useToast();

    const getCompany = store.getters['company/getCompany'];

    const loadCompany = async () => {
      // 2023-01-27
      loading.value = true;

      try {
        await store.dispatch('company/fetchCompany', props.companyID);

        iCompany.value = getCompany(props.companyID);
      } catch (error) {
        console.error(error);

        toast.error(error?.response.data?.message || error);
        return null;
      } finally {
        loading.value = false;
      }
    };

    const companySave = (data) => {
      // 2023-01-18
      iCompany.value = getCompany(props.companyID);

      data.closeDialog();
    };

    const companyName = computed(() => {
      // 2022-11-12
      if (!iCompany.value) return null;

      return iCompany.value.getName() ?? null;
    });

    const companyCVR = computed(() => {
      // 2022-11-12
      if (!iCompany.value) return null;

      return iCompany.value.getCVR() ?? null;
    });

    const userIDs = computed(() => {
      // 2022-11-13
      const comapnyUserIDs = [];
      for (const user of users.value) comapnyUserIDs.push(user.userID);

      return comapnyUserIDs;
    });

    const normalizedCompanyID = computed(() => {
      // 2023-01-18
      if (!iCompany.value) return props.companyID;

      const companyID = iCompany.value.getCompanyID() ?? props.companyID;

      return companyID;
    });

    onMounted(() => {
      // 2022-11-12
      iCompany.value = getCompany(props.companyID);

      loadCompany();
    });

    return {
      loading,
      iCompany,
      companyName,
      companyCVR,
      users,
      tab,
      getCompany,
      companySave,
      userIDs,
      normalizedCompanyID,
    };
  },
};
</script>

<style lang="scss" scoped></style>
