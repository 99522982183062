<template>
  <confirm-dialog v-model="dialog" :title="title" :confirm-text="$t('Save')" @confirm="save">
    <template #head>
      <div class="d-flex align-center" style="width: 100%">
        {{ title }}
        <v-spacer></v-spacer>
        <v-btn v-if="!isCreatingNote" icon="mdi-delete" variant="plain">
          <v-icon>mdi-delete</v-icon>
          <confirm-dialog
            :text="$t('Are you sure you want to delete this note?')"
            :confirm-text="$t('Delete')"
            confirm-color="error"
            width="auto"
            @confirm="deleteNote"
          ></confirm-dialog>
        </v-btn>
      </div>
    </template>
    <v-form>
      <v-text-field v-model="iNoteEdit._name" label="Title"></v-text-field>
      <v-textarea v-model="iNoteEdit._content" label="Description"></v-textarea>
    </v-form>
  </confirm-dialog>
</template>

<script>
import { ref, computed } from 'vue';
import { useI18n } from 'vue-i18n';

import ConfirmDialog from '@components/dialogs/ConfirmDialog.vue';

import Note from '@models/Note.mjs';

export default {
  name: 'EditNoteDialog',
  components: { ConfirmDialog },
  props: {
    iNote: {
      type: Note, // String, Number, Boolean, Function, Object, Array
      required: false,
      default: null,
    },
  },
  emits: ['save', 'delete'],
  setup(props, { emit, attrs }) {
    const { t } = useI18n();

    const dialog = ref(false);
    const formLoading = ref(false);
    const iNoteEdit = ref(props.iNote?.clone() ?? new Note());

    const isCreatingNote = computed(() => {
      return props.iNote === null;
    });

    const noteID = computed(() => {
      if (isCreatingNote.value) return null;

      return props.iNote.getID();
    });

    const title = computed(() => {
      if (isCreatingNote.value) return t('Create Note');

      return t('Edit Note#{noteID}', { noteID: props.iNote.getID() });
    });

    const save = (data) => {
      formLoading.value = true;

      emit('save', { iNote: iNoteEdit.value, closeDialog: data.closeDialog });
    };

    const deleteNote = (data) => {
      formLoading.value = true;

      const closeDialog = () => {
        data.closeDialog();
        dialog.value = false;
      };

      emit('delete', { iNote: iNoteEdit.value, closeDialog });
    };

    const hasListener = (iEventName) => {
      return (attrs?.[iEventName] ?? null) !== null;
    };

    return {
      dialog,
      formLoading,
      iNoteEdit,
      isCreatingNote,
      noteID,
      title,
      save,
      deleteNote,
      hasListener,
    };
  },
};
</script>

<style lang="scss" scoped></style>
