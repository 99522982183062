class PRODUCT_UNIT {
  static get _ALL() {
    // Get the names of all static properties/methods on the class
    const properties = Object.getOwnPropertyNames(PRODUCT_UNIT);

    // Remove the ones that are not uppercase
    const filteredProps = properties.filter((property) => {
      return property === property.toUpperCase() && property !== '_ALL';
    });

    return filteredProps.map((property) => {
      return PRODUCT_UNIT[property];
    });
  }

  static get HOURS() {
    return 'hours';
  }
  static get PCS() {
    return 'PCS.';
  }
  static get KM() {
    return 'km';
  }
  static get DAYS() {
    return 'days';
  }
  static get WEEKS() {
    return 'weeks';
  }
  static get MONTHS() {
    return 'months';
  }
  static get KG() {
    return 'kg';
  }
  static get CUBIC_METERS() {
    return 'cubic meters';
  }
  static get SET() {
    return 'set';
  }
  static get LITRES() {
    return 'litres';
  }
  static get BOXES() {
    return 'boxes';
  }
  static get CARTONS() {
    return 'cartons';
  }
  static get METERS() {
    return 'meters';
  }
  static get PACKAGES() {
    return 'packages';
  }
  static get SHIPMENTS() {
    return 'shipments';
  }
  static get SQ_M() {
    return 'sq.m';
  }
  static get SESSIONS() {
    return 'sessions';
  }
  static get TON() {
    return 'ton';
  }
}

class Product {
  productID = null;
  name = '';
  quantity = 0;
  priceExTax = 0;
  taxRate = 25;
  unit = PRODUCT_UNIT.PCS;
  description = '';
  deleted = null;
  updated = null;
  created = null;

  static create(data) {
    const product = new Product();

    if (Product.validateUnit(data.unit) === false) {
      throw new Error('Invalid unit: ' + data.unit);
    }

    product.productID = data.productID;
    product.name = data.name;
    product.quantity = data.quantity;
    product.priceExTax = data.priceExTax;
    product.taxRate = data.taxRate;
    product.unit = data.unit;
    product.description = data.description;
    product.deleted = data.deleted;
    product.updated = data.updated;
    product.created = data.created;

    return product;
  }

  clone() {
    const data = JSON.parse(JSON.stringify(this));

    return Product.create(data);
  }

  static validateUnit(unit) {
    return PRODUCT_UNIT._ALL.includes(unit);
  }

  static formatPrice(price) {
    const currencyFormatter = new Intl.NumberFormat('da-DK', {
      style: 'currency',
      currency: 'DKK',

      // These options are needed to round to whole numbers if that's what you want.
      //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
      //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
    });

    return currencyFormatter.format(price);
  }

  getUnitPrice(addTax = true) {
    if (addTax) {
      return this.priceExTax * (1 + this.taxRate / 100);
    }

    return this.priceExTax;
  }
}

export default Product;
export { Product, PRODUCT_UNIT };
