// 2023-08-05

import axios from 'axios';

import Address from '@models/Address.mjs';

class AddressAPI {
  static async fetchAddress(addressID /*: ?object*/) {
    // 2022-11-12
    const response = await axios.get('/api/address/' + addressID);

    return response;
  }

  static async fetchAddresses() {
    // 2022-12-17
    const response = await axios.get('/api/address/fetch-all');

    return response;
  }

  static async pushAddress(iAddress) {
    // 2023-01-18
    if (iAddress.constructor !== Address)
      throw new Error(
        'Invalid address. must be an instance of Address. Received: ' +
          iAddress.constructor.name,
      );

    const data = {
      // addressID: iAddress.getID(),
      type: iAddress.getType(),
      streetAddress: iAddress.getStreetAddress(),
      secondaryAddress: iAddress.getSecondaryAddress(),
      district: iAddress.getDistrict(),
      city: iAddress.getCity(),
      postalCode: iAddress.getPostalCode(),
      phone: iAddress.getPhone(),
      countryID: iAddress.getCountryID(),
    };

    const response = await axios.post(
      '/api/address/' + (data.addressID ?? ''),
      data,
    );

    return response;
  }
}

export default AddressAPI;
