import Toast, { POSITION } from 'vue-toastification';
// Import the CSS or use your own!
import 'vue-toastification/dist/index.css';

const toastConfig = {
  transition: 'vue-toastification__bounce',
  maxtoasts: 5, // only show 5 at a time. the rest will be queued
  newestontop: true,
  position: POSITION.BOTTOM_CENTER,
};

export default Toast;
export { toastConfig };
