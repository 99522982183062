<template>
  <v-dialog v-model="dialog" activator="parent" fullscreen :scrim="false" hide-overlay>
    <template #default="{ isActive }">
      <v-card>
        <v-card-title class="text-h5">
          {{ editCompanyTitle }}
        </v-card-title>
        <v-card-text>
          <v-form ref="mainForm" v-model="formIsValid" lazy-validation :loading="formLoading">
            <div class="h4">{{ $t('General') }}</div>
            <v-divider class="mb-4 mt-1"></v-divider>
            <v-row>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="iEditedCompany.name"
                  :label="$t('Name')"
                  :rules="nameRules"
                  :counter="255"
                  :disabled="formLoading"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="iEditedCompany.CVR"
                  :label="$t('CVR')"
                  :rules="cvrRules"
                  :counter="255"
                  :disabled="formLoading"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-select
                  v-model="iEditedCompany.contactUsers"
                  :items="assignableContactPeople"
                  :rules="contactUsersRules"
                  :label="$t('Default Contact People')"
                  :hint="$t('Contact people will be marked for notifications by default')"
                  item-text="name"
                  return-object
                  clearable
                  :disabled="formLoading"
                  :loading="formLoading"
                ></v-select>
              </v-col>
            </v-row>
            <div class="h4">{{ $t('Address') }}</div>
            <v-divider class="mb-4 mt-1"></v-divider>
            <v-btn v-bind="attrs" icon="mdi-plus" variant="plain" :title="$t('Add note')">
              <v-icon>mdi-plus</v-icon>
              <edit-address-dialog @save="saveAddress"> </edit-address-dialog>
            </v-btn>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn plain :disabled="formLoading" @click="isActive.value = false">{{ $t('Cancel') }}</v-btn>

          <v-btn color="primary" :disabled="formLoading" :loading="formLoading" @click="save">{{ $t('Save') }}</v-btn>
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
import { ref, computed, onMounted } from 'vue';
import { useStore } from 'vuex';
import { useToast } from 'vue-toastification';
import { useI18n } from 'vue-i18n';

import Company from '@models/Company.mjs';

import EditAddressDialog from '@components/EditAddressDialog.vue';

export default {
  name: 'EditCompanyDialog',
  components: {
    EditAddressDialog,
  },
  props: {
    iCompany: {
      type: Company,
      required: false,
      default: null,
    },
  },
  emits: ['save'],
  setup(props, { refs, emit, attrs }) {
    const store = useStore();
    const toast = useToast();
    const { t } = useI18n();

    const dialog = ref(false);
    const formIsValid = ref(true);
    const formLoading = ref(false);
    const iEditedCompany = ref(props.iCompany ? props.iCompany.clone() : new Company());

    console.log('iEditedCompany', iEditedCompany.value, props.iCompany);
    // const formData = ref({
    //   name: '',
    //   cvr: '',
    //   contactUsers: [],
    //   address: {
    //     addressID: null,
    //     streetAddress: '',
    //     secondaryStreetAddress: '',
    //     city: '',
    //     postalCode: '',
    //     countryID: null,
    //   },
    // });

    const assignableContactPeople = ref([]);
    const countries = ref([]);

    const save = async () => {
      // 2023-01-18
      await refs.mainForm.validate();

      if (!formIsValid.value) return;

      formLoading.value = true;

      try {
        const message = await store.dispatch('company/pushCompany', iEditedCompany.value);

        toast.success(message);

        if (!hasSaveListener.value) dialog.value = false;

        const closeDialog = () => {
          dialog.value = false;
        };

        emit('save', { iCompany: iEditedCompany.value, closeDialog: closeDialog });
      } catch (error) {
        console.error(error);

        toast.error(error.message ?? error);
      } finally {
        formLoading.value = hasSaveListener.value;
      }
    };

    const cancel = () => {
      // 2023-01-18
      dialog.value = false;
    };

    const loadAssignableContactPeople = async () => {
      // 2023-01-29
      try {
        assignableContactPeople.value = await store.dispatch('company/fetchAssignableContactPeople');
      } catch (error) {
        console.error(error);

        toast.error(error.message ?? error);
      }
    };

    const loadCountries = async () => {
      // 2023-01-18
      try {
        countries.value = await store.dispatch('country/fetchCountries');
      } catch (error) {
        console.error(error);

        toast.error(error.message ?? error);
      }
    };

    const saveAddress = (saveData) => {
      // 2023-01-18
      const addressData = saveData.addressData;
      const closeDialog = saveData.closeDialog;

      iEditedCompany.value.addresses.push(addressData);

      closeDialog();
    };

    const editCompanyTitle = computed(() => {
      // 2023-01-18
      const companyID = iEditedCompany.value?.companyID ?? null;

      const title = companyID === null ? t('Create Company') : t('Edit Company#{companyID}', { companyID });

      return title;
    });

    const nameRules = computed(() => {
      // 2023-01-18
      const rules = [
        (v) => !!v || t('Name is required'),
        (v) => (v && v.length < 256) || t('Name must be less than 256 characters'),
      ];

      return rules;
    });

    const cvrRules = computed(() => {
      // 2023-01-18
      const rules = [
        (v) => !!v || t('CVR is required'),
        (v) => (v && v.length < 256) || t('CVR must be less than 256 characters'),
      ];

      return rules;
    });

    const contactUsersRules = computed(() => {
      // 2023-01-29
      return [];
    });

    const hasSaveListener = computed(() => {
      // 2023-01-18
      return (attrs?.save ?? null) !== null;
    });

    onMounted(() => {
      // 2023-01-18
      loadAssignableContactPeople();
      loadCountries();
    });

    return {
      dialog,
      formIsValid,
      formLoading,
      iEditedCompany,
      assignableContactPeople,
      countries,
      save,
      cancel,
      loadCountries,
      saveAddress,
      editCompanyTitle,
      nameRules,
      cvrRules,
      contactUsersRules,
      hasSaveListener,
    };
  },
};
</script>

<style lang="scss" scoped></style>
