/* eslint-disable prettier/prettier */
import { createI18n } from 'vue-i18n';
import axios from 'axios';

const userLocale = navigator.language || navigator.userLanguage;
// eslint-disable-next-line no-unused-vars
const shortLocale = userLocale.split('-')[0]; // To get 'en' from 'en-US'

const missingTranslations = {};

window.getMissingTranslations = () => missingTranslations;

const i18n = createI18n({
  legacy: false,
  locale: 'da',
  fallbackLocale: 'en',
  // eslint-disable-next-line no-unused-vars
  missing: (locale, key, vm) => {
    console.log(`Missing translation for key: ${key}`);
    missingTranslations[key] = key;
  },
});

const loadedLanguages = []; // our default language that is preloaded

const setI18nLanguage = (lang) => {
  i18n.global.locale = lang;
  axios.defaults.headers.common['Accept-Language'] = lang;
  document.querySelector('html').setAttribute('lang', lang);
  return lang;
};

const loadLanguageAsync = async (lang) => {
  // If the same language
  if (i18n.global.locale === lang) {
    return setI18nLanguage(lang);
  }

  // If the language was already loaded
  if (loadedLanguages.includes(lang)) {
    return setI18nLanguage(lang);
  }

  const messages = await import(/* webpackChunkName: "lang-[request]" */ `@locales/${lang}/index`);

  const [shortLocale] = lang.split('-');

  i18n.global.setLocaleMessage(shortLocale, messages.default);

  loadedLanguages.push(lang);

  return setI18nLanguage(lang);
};

export default i18n;
export { i18n, loadLanguageAsync };
