// 2023-08-05

import axios from 'axios';

class CountryAPI {
  static async fetchCountries(countryIDs /*: ?array*/) {
    // 2022-11-12
    const response = await axios.get(
      '/api/country/fetch-all?countryIDs=' + encodeURIComponent(countryIDs),
    );

    return response;
  }

  static async fetchCountry(countryID /*: ?object*/) {
    // 2022-11-12
    const response = await axios.get('/api/country/' + countryID);

    return response;
  }

  static async pushCountry(countryData) {
    // 2022-11-12
    const data = {
      name: countryData.name,
      alphaCode2: countryData.alphaCode2,
      alphaCode3: countryData.alphaCode3,
      numeric: countryData.numeric,
    };

    const response = await axios.post(
      '/api/country/' + (countryData.countryID ?? ''),
      data,
    );

    return response;
  }
}

export default CountryAPI;
