<template>
  <div>
    <section class="users mb-6">
      <company-users-overview :company-i-d="companyID"></company-users-overview>
    </section>
    <section class="projects mb-6">
      <company-projects-overview :company-i-d="companyID"></company-projects-overview>
    </section>
  </div>
</template>

<script>
import CompanyUsersOverview from '@views/Company/CompanyUsersOverview';
import CompanyProjectsOverview from '@views/Company/CompanyProjectsOverview';

export default {
  name: 'CompanyOverviewTab',
  components: { CompanyUsersOverview, CompanyProjectsOverview },
  props: {
    companyID: {
      type: [String, Number], // String, Number, Boolean, Function, Object, Array
      required: true,
      default: null,
    },
  },
};
</script>

<style></style>
