<template>
  <section class="home">
    <v-row justify="center" class="fill-height">
      <v-col cols="12">
        <v-row v-if="loading" align="center" justify="center">
          <v-progress-circular indeterminate color="primary" :size="72"></v-progress-circular>
        </v-row>
        <company-view v-else-if="hasCompany" :company-i-d="Number(mainCompanyID)"></company-view>
        <v-row v-else align="center" justify="center">
          <v-alert border="top" colored-border type="info" elevation="2">
            You have not been assigned any company yet.
          </v-alert>
        </v-row>
      </v-col>
    </v-row>
  </section>
</template>

<script>
import CompanyView from '@views/Company.vue';
import UserAPI from '@api/UserAPI.mjs';

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Home',
  components: { CompanyView },
  data() {
    const data = {
      loading: true,
      mainCompanyID: null,
    };

    return data;
  },
  computed: {
    hasCompany() {
      // 2023-01-25
      if (this.loading) return false;

      return this.mainCompanyID !== null;
    },
  },
  async mounted() {
    // 2023-01-25
    await this.fetchMainCompanyID();
  },
  methods: {
    async fetchMainCompanyID() {
      // 2022-12-23
      this.loading = true;

      try {
        const response = await UserAPI.fetchMainCompany();

        this.mainCompanyID = response.data?.company?.companyID ?? null;
      } catch (error) {
        console.error(error);

        if (error.request.status !== 404) this.$toast.error(error.message);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss">
section.home {
  height: 100%;

  .construct {
    max-height: 400px;
    height: auto;
    width: 100%;
  }
}
</style>
