// 2023-09-24

import OfferProduct from '@models/OfferProduct.mjs';

class Offer {
  offerID;
  title;
  dueDate;
  iOfferProducts;
  deleted;
  updated;
  created;

  constructor() {}

  static create(data) {
    if (data.constructor.name === 'Offer') return data.clone();

    const iOffer = new Offer();

    iOffer.offerID = data.offerID;
    iOffer.title = data.title;
    iOffer.dueDate = data.dueDate;
    iOffer.deleted = data.deleted;
    iOffer.updated = data.updated;
    iOffer.created = data.created;

    iOffer.iOfferProducts = [];

    for (const iOfferProduct of data.iOfferProducts ?? data.offerProducts ?? []) {
      iOffer.iOfferProducts.push(OfferProduct.create(iOfferProduct));
    }

    return iOffer;
  }

  clone() {
    const data = JSON.parse(JSON.stringify(this));

    return Offer.create(data);
  }

  getID() {
    return this.offerID;
  }

  getTitle() {
    return this.title;
  }

  getDueDate() {
    if (!this.dueDate) return null;

    return new Date(this.dueDate);
  }

  getOfferProducts() {
    return this.iOfferProducts;
  }
}

export default Offer;
