// 2022-11-16

import { useToast } from 'vue-toastification';
import axios from 'axios';

const toast = useToast();

class TaskAPI {
  static get STATUS_OPEN() {
    return 'STATUS_OPEN';
  }
  static get STATUS_CLOSE() {
    return 'STATUS_CLOSE';
  }

  static get TYPE_INTERNAL() {
    return 'INTERNAL';
  }
  static get TYPE_EXTERNAL() {
    return 'EXTERNAL';
  }

  static async fetchTask(taskID) {
    // 2022-11-16
    const response = await axios.get('/api/task/' + encodeURIComponent(taskID));

    return response;
  }

  static async fetchAssignableProjects(companyID) {
    // 2022-11-19
    const params = {
      companyID: companyID,
    };

    const response = await axios.get('/api/task/fetch-assignable-projects', { params: params });

    return response;
  }

  static async fetchAssignableUsers() {
    // 2022-11-19
    const response = await axios.get('/api/task/fetch-assignable-users');

    return response;
  }

  static async fetchCompany(taskID) {
    // 2022-11-20
    const response = await axios.get('/api/task/' + encodeURIComponent(taskID) + '/fetch-company');

    return response;
  }

  static async fetchProject(taskID) {
    // 2022-12-17
    try {
      const response = await axios.get('/api/task/' + encodeURIComponent(taskID) + '/fetch-project');

      return response.data.project;
    } catch (error) {
      console.error(error);

      toast.error(error?.response.data?.message || error);

      return null;
    }
  }

  static async pushTaskMessage(taskID, formData) {
    // 2023-01-28
    const response = await axios.post('/api/task/' + taskID + '/comment', formData);

    return response;
  }

  static async fetchTaskMessages(taskID) {
    // 2022-11-26
    const response = await axios.get('/api/task/' + encodeURIComponent(taskID) + '/comment/fetch-all');

    return response;
  }

  static async deleteTaskMessage(taskID, taskMessageID) {
    // 2022-11-30
    const response = await axios.delete('/api/task/' + encodeURIComponent(taskID) + '/comment/' + encodeURIComponent(taskMessageID));

    return response;
  }

  static getFileDownloadURL(taskID, checksum) {
    // 2022-11-30
    return '/api/task/' + encodeURIComponent(taskID) + '/file/' + checksum + '/download';
  }

  static getFileURL(taskID, checksum) {
    // 2022-11-30
    return '/api/task/' + encodeURIComponent(taskID) + '/file/' + checksum + '/open';
  }

  static async deleteTask(taskID) {
    // 2023-01-28
    const response = await axios.delete('/api/task/' + encodeURIComponent(taskID));

    return response;
  }

  static async pushTask(taskData) {
    // 2023-01-19
    const data = {
      name: taskData.name,
      priority: taskData.priority,
      assignedUserIDs: taskData.assignedUserIDs,
      remark: taskData.remark,
      type: taskData?.type ?? TaskAPI.TYPE_EXTERNAL,
      projectID: taskData.projectID,
      QAUserID: taskData.QAUserID,
    };

    const response = await axios.post('/api/task/' + encodeURIComponent(taskData?.taskID ?? ''), data);

    return response;
  }

  static async open(taskID, userIDsToNotify) {
    // 2023-01-25
    const url = '/api/task/' + encodeURIComponent(taskID) + '/open';

    const data = {
      userIDsToNotify: userIDsToNotify,
    };

    const response = await axios.post(url, data);

    return response;
  }

  static async close(taskID, userIDsToNotify) {
    // 2023-01-25
    const url = '/api/task/' + encodeURIComponent(taskID) + '/close';

    const data = {
      userIDsToNotify: userIDsToNotify,
    };

    const response = await axios.post(url, data);

    return response;
  }
}

export default TaskAPI;
