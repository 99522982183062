// 2022-11-12

import axios from 'axios';

class UserAPI {
  static async fetchUser(userID) {
    // 2022-11-12
    const response = await axios.get('/api/user/' + encodeURIComponent(userID));

    return response;
  }

  static async fetchUsers() {
    // 2022-11-12
    const response = await axios.get('/api/user/fetch-all');

    return response;
  }

  static async fetchAllRoles() {
    // 2022-12-21
    const response = await axios.get('/api/user/fetch-all-roles');

    return response;
  }

  static async fetchMainCompany() {
    // 2022-12-23
    const response = await axios.get('/api/user/fetch-main-company');

    return response;
  }

  static async pushUser(userData) {
    // 2023-01-18
    const data = {
      givenName: userData.givenName,
      familyName: userData.familyName,
      emailAddress: userData.emailAddress,
      password: userData?.password ?? null,
      phoneNumber: userData.phoneNumber,
      roleIDs: userData.roleIDs,
    };

    const response = await axios.post(
      '/api/user/' + encodeURIComponent(userData.userID ?? ''),
      data,
    );

    return response;
  }

  static async deleteUser(userID) {
    // 2023-01-22
    const url = '/api/user/' + encodeURIComponent(userID);

    const response = await axios.delete(url);

    return response;
  }
}

export default UserAPI;
