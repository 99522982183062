<template>
  <flat-card :title="$t('Users')">
    <template #head="{ title }">
      <div class="head d-flex align-center">
        <div class="text-h5">{{ title }}</div>
        <v-fade-transition>
          <authorized-area :roles="['Developer', 'Administrator']">
            <v-btn
              :attrs="attrs"
              icon="mdi-plus"
              variant="plain"
              :title="$t('Add users to company')"
              :loading="assigningUsersLoading"
            >
              <v-icon>mdi-plus</v-icon>
              <user-select-dialog
                v-if="!itemsLoading"
                :loading="assigningUsersLoading"
                :selected-user-ids="userIDs"
                @select="assignUsers"
              ></user-select-dialog>
            </v-btn>
          </authorized-area>
        </v-fade-transition>
      </div>
      <v-divider class="mb-4 mt-1"></v-divider>
    </template>
    <v-data-table
      style="width: 100%"
      :headers="headers"
      :items="items"
      item-key="userID"
      :loading="itemsLoading"
      class="row-pointer"
      @click:row="rowClick"
    >
    </v-data-table>
  </flat-card>
</template>

<script>
import { ref, computed, onMounted } from 'vue';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import { useToast } from 'vue-toastification';
import { useRouter } from 'vue-router';

import FlatCard from '@components/FlatCard.vue';
import UserSelectDialog from '@components/UserSelectDialog.vue';
import AuthorizedArea from '@components/AuthorizedArea.vue';

import moment from 'moment';

export default {
  name: 'CompanyUsersOverview',
  components: { FlatCard, UserSelectDialog, AuthorizedArea },
  props: {
    companyID: {
      type: [String, Number], // String, Number, Boolean, Function, Object, Array
      required: true,
      default: null,
    },
  },
  setup(props) {
    const store = useStore();
    const toast = useToast();
    const router = useRouter();
    const { t } = useI18n();

    const headers = ref([
      {
        title: t('Name'),
        align: 'start',
        sortable: false,
        key: 'name',
      },
      {
        title: t('Email'),
        align: 'start',
        sortable: false,
        key: 'email',
      },
      {
        title: t('Last Access'),
        align: 'start',
        sortable: false,
        key: 'lastAccess',
      },
    ]);

    const itemsLoading = ref(true);
    const assigningUsersLoading = ref(false);

    const getUsers = store.getters['company/getUsers'];

    const loadUsers = async () => {
      // 2023-01-27
      itemsLoading.value = true;

      try {
        await store.dispatch('company/fetchUsers', props.companyID);
      } catch (error) {
        console.error(error);

        toast.error(error?.response.data?.message || error.message);
      } finally {
        itemsLoading.value = false;
      }
    };

    const assignUsers = async ({ users, closeDialog }) => {
      // 2023-01-18
      const userIDs = [];
      for (const user of users) userIDs.push(user.userID);

      assigningUsersLoading.value = true;

      try {
        const message = await store.dispatch('company/pushAssignedUsers', {
          companyID: props.companyID,
          userIDs: userIDs,
        });

        toast.success(message);

        closeDialog();

        loadUsers();
      } catch (error) {
        console.error(error);

        toast.error(error.message);
      } finally {
        assigningUsersLoading.value = false;
      }
    };

    const rowClick = (event, item) => {
      // 2023-01-18
      router.push({ name: 'User', params: { userID: item.item.userID } });
    };

    const formatDate = (date) => {
      // 2023-01-22
      return moment(date).format('YYYY-MM-DD HH:mm');
    };

    const userIDs = computed(() => {
      // 2023-01-18
      if (itemsLoading.value) return [];

      const ids = [];
      for (const user of getUsers(props.companyID)) ids.push(user.userID);

      return ids;
    });

    const items = computed(() => {
      const users = [];
      for (const user of getUsers(props.companyID)) {
        let lastAccess = 'N/A';
        if (user.lastAccess) lastAccess = formatDate(user.lastAccess);

        const item = {
          userID: user.userID,
          name: user.givenName + ' ' + user.familyName,
          email: user.emailAddress,
          lastAccess: lastAccess,
        };

        users.push(item);
      }

      return users;
    });

    onMounted(() => {
      // 2023-01-18
      loadUsers();
    });

    return {
      headers,
      items,
      itemsLoading,
      assigningUsersLoading,
      assignUsers,
      rowClick,
      formatDate,
      userIDs,
      onMounted,
    };
  },
};
</script>

<style lang="scss" scoped></style>
