<template>
  <div v-if="loading" class="loading">
    <v-progress-circular :size="70" :width="7" color="purple" indeterminate> Loading... </v-progress-circular>
  </div>
  <div v-else class="router">
    <v-app>
      <navigation-sidebar></navigation-sidebar>
      <navigation-topbar></navigation-topbar>
      <v-main>
        <router-view></router-view>
        <v-fab-transition>
          <v-btn v-show="fabScrollToTop" v-scroll="onScroll" fab dark fixed bottom right color="primary" @click="toTop">
            <v-icon>mdi-chevron-up</v-icon>
          </v-btn>
        </v-fab-transition>
      </v-main>
    </v-app>
  </div>
</template>

<script>
import { ref, computed, onMounted } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';

import NavigationSidebar from '@views/Navigation/NavigationSidebar.vue';
import NavigationTopbar from '@views/Navigation/NavigationTopbar.vue';

export default {
  name: 'NavigationShell',
  components: { NavigationSidebar, NavigationTopbar },
  setup() {
    const store = useStore();
    const router = useRouter();

    const loading = ref(true);

    const initialLoad = async () => {
      await store.dispatch('user/initialLoad');
    };

    const isAuthenticated = computed(() => store.getters['user/isAuthenticated']);

    const fabScrollToTop = ref(false);

    // On component mount
    onMounted(async () => {
      // Initialize theme

      try {
        // Initial load
        await initialLoad();
      } catch (error) {
        console.log(error);
      } finally {
        // Hide loading
        loading.value = false;
      }

      if (!isAuthenticated.value) {
        // Redirect to login page
        router.push({ name: 'Login' });
      }
    });

    const onScroll = (event) => {
      if (typeof window === 'undefined') return;

      const top = window.pageYOffset || event.target.scrollTop || 0;

      // NOTE: Maybe? I don't really know. If the button is visible it could obstruct functional elements on the page
      const atBottom = window.innerHeight + window.scrollY >= document.body.scrollHeight;

      fabScrollToTop.value = top > window.innerHeight && !atBottom;
    };

    const toTop = () => {
      store.dispatch('system/goTo', 0);
    };

    return {
      fabScrollToTop,
      loading,
      onScroll,
      toTop,
    };
  },
};
</script>

<style></style>
