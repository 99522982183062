<template>
  <div>
    <v-row>
      <v-col cols="12">
        <notes-card v-model="notes" @save-note="saveNote" @delete-note="deleteNote"></notes-card>
      </v-col>
      <v-col v-for="address in addresses" :key="address.addressID" cols="12" md="6">
        <address-card :address="address"></address-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { ref, onMounted, computed } from 'vue';
import { useStore } from 'vuex';
import { useToast } from 'vue-toastification';

import AddressCard from '@components/AddressCard.vue';
import NotesCard from '@components/NotesCard.vue';

export default {
  name: 'CompanyDetailsTab',
  components: {
    AddressCard,
    NotesCard,
  },
  props: {
    companyID: {
      type: [String, Number], // String, Number, Boolean, Function, Object, Array
      required: true,
      default: null,
    },
  },
  setup(props) {
    const store = useStore();
    const toast = useToast();

    const getCompany = store.getters['company/getCompany'];
    const getAddresses = store.getters['company/getAddresses'];
    const getNotes = store.getters['company/getNotes'];

    const iCompany = ref(getCompany(props.companyID));
    const addresses = ref([]);
    const iNotes = ref([]);

    const isAddressesLoaded = store.getters['company/isAddressesLoaded'];
    const isNotesLoaded = store.getters['company/isNotesLoaded'];
    const isCountriesLoaded = store.getters['country/isCountriesLoaded'];

    const loadAddresses = async () => {
      try {
        await store.dispatch('company/fetchAddresses', props.companyID);
      } catch (error) {
        console.error(error);

        toast.error(error?.response?.data?.message ?? error.message);

        return null;
      }
    };

    const loadNotes = async () => {
      try {
        await store.dispatch('company/fetchNotes', props.companyID);
      } catch (error) {
        console.error(error);

        toast.error(error?.response?.data?.message ?? error.message);

        return null;
      }
    };

    const loadCountries = async () => {
      try {
        await store.dispatch('country/fetchCountries');
      } catch (error) {
        console.error(error);

        toast.error(error?.response?.data?.message ?? error.message);

        return null;
      }
    };

    const saveNote = async ({ iNote, closeDialog }) => {
      try {
        const response = await store.dispatch('company/pushNote', { companyID: props.companyID, iNote: iNote });

        closeDialog();

        return response;
      } catch (error) {
        console.error(error);

        toast.error(error?.response?.data?.message ?? error.message);

        return null;
      }
    };

    const deleteNote = async ({ iNote, closeDialog }) => {
      try {
        const response = await store.dispatch('company/deleteNote', {
          companyID: props.companyID,
          noteID: iNote.noteID,
        });

        closeDialog();

        return response;
      } catch (error) {
        console.error(error);

        toast.error(error?.response?.data?.message ?? error.message);

        return null;
      }
    };

    const notes = computed(() => {
      return iNotes.value;
    });

    onMounted(async () => {
      if (!isAddressesLoaded(props.companyID)) await loadAddresses();
      if (!isNotesLoaded(props.companyID)) await loadNotes();
      if (!isCountriesLoaded) await loadCountries();

      iCompany.value = getCompany(props.companyID);
      addresses.value = getAddresses(props.companyID);
      iNotes.value = getNotes(props.companyID);
    });

    return {
      iCompany,
      addresses,
      notes,
      saveNote,
      deleteNote,
      isAddressesLoaded,
      isNotesLoaded,
      isCountriesLoaded,
      loadAddresses,
      loadNotes,
      loadCountries,
    };
  },
};
</script>

<style></style>
