<template>
  <base-dialog v-bind="$props" :loading="cardLoading">
    <template #head="{ isActive }">
      <slot
        name="head"
        :is-active="isActive"
        :title="title"
        :card-loading="cardLoading"
        :confirm-loading="confirmLoading"
        >{{ title }}</slot
      >
    </template>
    <template #body>
      <slot name="default" :card-loading="cardLoading" :confirm-loading="confirmLoading">
        <pre class="text-body-1">{{ text }}</pre>
      </slot>
    </template>
    <template #actions="{ isActive }">
      <slot name="actions" :is-active="isActive" :card-loading="cardLoading" :confirm-loading="confirmLoading">
        <v-spacer></v-spacer>
        <v-btn :disabled="confirmLoading || cardLoading" variant="plain" @click="cancel(isActive)">
          {{ cancelTextComputed }}
        </v-btn>
        <v-btn
          :disabled="confirmLoading || cardLoading"
          :loading="confirmLoading"
          :color="confirmColor"
          @click="confirm(isActive)"
          >{{ confirmTextComputed }}</v-btn
        >
      </slot>
    </template>
  </base-dialog>
</template>

<script>
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import BaseDialog from '@components/dialogs/BaseDialog.vue';

export default {
  name: 'ConfirmDialog',
  components: { BaseDialog },
  extends: BaseDialog,
  props: {
    text: {
      type: String, // String, Number, Boolean, Function, Object, Array
      required: false,
      default: null,
    },
    confirmText: {
      type: String, // String, Number, Boolean, Function, Object, Array
      required: false,
      default: null,
    },
    confirmColor: {
      type: String, // String, Number, Boolean, Function, Object, Array
      required: false,
      default: 'primary',
    },
    cancelText: {
      type: String, // String, Number, Boolean, Function, Object, Array
      required: false,
      default: null,
    },
    confirmLoading: {
      type: Boolean, // String, Number, Boolean, Function, Object, Array
      required: false,
      default: false,
    },
    cardLoading: {
      type: Boolean, // String, Number, Boolean, Function, Object, Array
      required: false,
      default: false,
    },
  },
  emits: ['input', 'cancel', 'confirm'],
  setup(props, { emit }) {
    const { t } = useI18n();

    const confirm = (isActive) => {
      emit('input', false);
      emit('confirm', { closeDialog: () => (isActive.value = false) });
    };

    const cancel = (isActive) => {
      emit('input', false);
      emit('cancel');
      isActive.value = false;
    };

    const confirmTextComputed = computed(() => {
      if (props.confirmText === null) {
        return t('OK');
      }

      return props.confirmText;
    });

    const cancelTextComputed = computed(() => {
      if (props.cancelText === null) {
        return t('Cancel');
      }

      return props.cancelText;
    });

    return {
      confirm,
      cancel,
      confirmTextComputed,
      cancelTextComputed,
    };
  },
};
</script>

<style lang="scss" scoped></style>
