import Utilities from '@models/Utilities.mjs';
import CompanyAPI from '@api/CompanyAPI.mjs';
import Company from '@models/Company.mjs';

import Note from '@models/Note.mjs';

const SET_COMPANIES = 'SET_COMPANIES';
const SET_COMPANY = 'SET_COMPANY';

const SET_USERS = 'SET_USERS';
const SET_PROJECT = 'SET_PROJECT';
const SET_PROJECTS = 'SET_PROJECTS';

const SET_ADDRESS_IDS = 'SET_ADDRESS_IDS';
const SET_NOTES = 'SET_NOTES';

const SET_CONTACT_PEOPLE = 'SET_CONTACT_PEOPLE';

const REMOVE_PROJECT = 'REMOVE_PROJECT';

export default {
  namespaced: true,
  state: {
    companies: {},
    users: {},
    projects: {},
    addressIDs: {},
    iNotes: {},
    notesLoaded: false,
  },
  getters: {
    getCompanies: (state) => {
      // 2023-01-27
      return Object.values(state.companies);
    },
    getCompany: (state) => (companyID) => {
      // 2023-01-27
      return state.companies[companyID] ?? null;
    },
    getUsers: (state) => (companyID) => {
      // 2023-01-27
      return state.users[companyID] ?? [];
    },
    getProjects: (state) => (companyID) => {
      // 2023-01-27
      return state.projects[companyID] ?? [];
    },
    getCedulasID: () => () => {
      // 2023-01-28
      return 1;
    },
    isAddressesLoaded: (state) => (companyID) => {
      const addressIDs = state.addressIDs[companyID] ?? null;

      return addressIDs !== null;
    },
    getAddresses: (state, getters, rootState, rootGetters) => (companyID) => {
      const iAddresses = [];

      const addressIDs = state.addressIDs[companyID] ?? null;

      if (addressIDs === null) return iAddresses;

      for (const addressID of addressIDs) {
        const iAddress = rootGetters['address/getAddress'](addressID);

        if (iAddress === null) continue;

        iAddresses.push(iAddress);
      }

      return iAddresses;
    },
    isNotesLoaded: (state) => (companyID) => {
      const iNotes = state.iNotes[companyID] ?? null;

      return iNotes !== null;
    },
    getNotes: (state) => (companyID) => {
      return state.iNotes[companyID] ?? [];
    },
  },
  mutations: {
    [SET_COMPANIES]: (state, companies) => {
      // 2023-01-27
      state.companies = {};

      for (const company of companies) {
        const iCompany = Company.create(company);
        state.companies[company.companyID] = iCompany;
      }
    },
    [SET_COMPANY]: (state, company) => {
      // 2023-01-27
      const iCompany = Company.create(company);

      state.companies[company.companyID] = iCompany;
    },
    [SET_USERS]: (state, { companyID, users }) => {
      // 2023-01-27
      state.users[companyID] = users;
    },
    [SET_PROJECTS]: (state, { companyID, projects }) => {
      // 2023-01-27
      state.projects[companyID] = projects;
    },
    [SET_PROJECT]: (state, project) => {
      // 2023-01-28
      state.projects[project.companyID].push(project);
    },
    [REMOVE_PROJECT]: (state, { companyID, projectID }) => {
      // 2023-01-28

      const filtered = state.projects[companyID].filter((project) => {
        return project.projectID !== projectID;
      });

      state.projects[companyID] = filtered;
    },
    [SET_ADDRESS_IDS]: (state, { companyID, addressIDs }) => {
      state.addressIDs[companyID] = state.addressIDs[companyID] ?? [];

      for (const addressID of addressIDs) {
        if (!state.addressIDs[companyID].includes(addressID)) {
          state.addressIDs[companyID].push(addressID);
        }
      }
    },
    [SET_NOTES]: (state, { companyID, notes }) => {
      state.iNotes[companyID] = state.iNotes[companyID] ?? [];

      for (const note of notes) {
        const iNote = Note.create(note);

        const hasNote = state.iNotes[companyID].find((iExistingNote) => iExistingNote.getID() === iNote.getID());

        if (!hasNote) {
          state.iNotes[companyID].push(iNote);
        } else {
          const index = state.iNotes[companyID].findIndex((iExistingNote) => iExistingNote.getID() === iNote.getID());

          state.iNotes[companyID][index] = iNote;
        }
      }

      state.notesLoaded = true;
    },
    // eslint-disable-next-line no-unused-vars
    [SET_CONTACT_PEOPLE]: (state, { companyID, contactPeople }) => {
      // const company = state.companies[companyID];
      // company.setContactPeople(contactPeople);
    },
  },
  actions: {
    fetchCompanies: async (context) => {
      // 2023-01-27
      const response = await CompanyAPI.fetchCompanies();

      const companies = response.data.companies;

      context.commit(SET_COMPANIES, companies);
    },
    fetchCompany: async (context, companyID) => {
      // 2023-01-27
      const response = await CompanyAPI.fetchCompany(companyID);

      context.commit(SET_COMPANY, response.data.company);
    },
    fetchAssignableContactPeople: async (context, companyID) => {
      // const response = await CompanyAPI.fetchAssignableContactPeople(companyID);

      // TODO: Fetch contact people from API
      const response = { data: { contactPeople: [] } };

      context.commit(SET_CONTACT_PEOPLE, { companyID, contactPeople: response.data.contactPeople });
    },
    pushCompany: async (context, companyData) => {
      // 2023-01-27
      const response = await CompanyAPI.pushCompany(companyData);

      companyData.companyID = response.data.companyID;

      const company = context.getters.getCompany(companyData.companyID);

      const mergedCompany = Utilities.mergeDeep(company ?? {}, companyData);

      context.commit(SET_COMPANY, mergedCompany);

      return response.data.message;
    },
    fetchUsers: async (context, companyID) => {
      // 2023-01-27
      const response = await CompanyAPI.fetchUsers(companyID);

      const users = response.data.users;

      context.commit(SET_USERS, { companyID, users });
    },
    pushAssignedUsers: async (context, { companyID, userIDs }) => {
      // 2023-01-28
      const response = await CompanyAPI.pushAssignedUsers(companyID, userIDs);

      return response.data.message;
    },
    fetchProjects: async (context, companyID) => {
      // 2023-01-27
      const response = await CompanyAPI.fetchProjects(companyID);

      const projects = response.data.projects;

      context.commit(SET_PROJECTS, { companyID, projects });
    },
    removeProject: (context, { companyID, projectID }) => {
      // 2023-01-28
      context.commit(REMOVE_PROJECT, { companyID, projectID });
    },
    setProject: (context, project) => {
      // 2023-01-28
      context.commit(SET_PROJECT, project);
    },
    fetchAddresses: async (context, companyID) => {
      // 2023-08-05
      // TODO: set them in address store
      const response = await CompanyAPI.fetchAddresses(companyID);

      const addresses = response.data.addresses;

      context.commit('address/SET_ADDRESSES', addresses, { root: true });
      context.commit(SET_ADDRESS_IDS, {
        companyID,
        addressIDs: addresses.map((address) => address.addressID),
      });
    },
    fetchNotes: async (context, companyID) => {
      // 2023-08-05
      const response = await CompanyAPI.fetchNotes(companyID);

      const notes = response.data.notes;

      context.commit(SET_NOTES, { companyID, notes });
    },
    pushNote: async (context, { companyID, iNote }) => {
      // 2023-08-05
      const data = {
        noteID: iNote.getID(),
        name: iNote.getName(),
        content: iNote.getContent(),
      };

      const response = await CompanyAPI.pushNote(companyID, data);

      const iNoteNew = Note.create(response.data.note);

      context.commit(SET_NOTES, { companyID, notes: [iNoteNew] });

      return iNoteNew;
    },
    deleteNoteByID: async (context, { companyID, noteID }) => {
      // 2023-08-05
      await CompanyAPI.deleteNoteByID(companyID, noteID);

      const iNotes = context.getters.getNotes(companyID);

      context.commit(SET_NOTES, {
        companyID,
        notes: iNotes.filter((iNote) => iNote.noteID !== noteID),
      });
    },
  },
};
